import React from 'react';
import AppNavigator from './router.js';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Helmet} from "react-helmet"

function App() {

  // global.url = 'http://localhost/erp/public/api/';
  // global.images = 'http://localhost/erp/public/'

  // global.url = "http://192.168.18.79:88/erp/public/api/";
  // global.images = "http://192.168.18.79:88/erp/public/";

  global.url = "https://one.nasscript.com/erp/public/api/";
  global.images = "https://one.nasscript.com/erp/public/";

  // global.url = "http://erpdemo.fetch.qa/erp/public/api/";
  // global.images = "http://erpdemo.fetch.qa/erp/public/";

  // global.url = "https://erp.fetch.qa/erp/public/api/";
  // global.images = "https://erp.fetch.qa/erp/public/";

  return (
    <div className="App">
       <div>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        </Helmet>
       </div>
      <AppNavigator />
    </div>
    
  );
}

export default App;
