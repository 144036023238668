import React, {useState, useEffect} from 'react';
import './Order.css';
import {Container, Row, Col, Button, Form } from "react-bootstrap";
import {useHistory} from 'react-router-dom';
import { FaUserAlt, FaListAlt } from "react-icons/fa";
import {FiLogIn} from "react-icons/fi"
import { MdDeliveryDining, MdLogout } from "react-icons/md";
import { AiFillHeart, AiFillStar } from "react-icons/ai";
import demo from '../../Assets/Images/products/shoe1.png'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../../Components/Loader/Loader'

import Header from '../../Components/Header/header';
import Footer from '../../Components/Footer/Footer';
import img from '../../Assets/Images/noimages.png';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

function Order() {
    let history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = useState("");
    const [logtoken, setLogtoken] = useState('')
    const [source, setSource] = useState([])
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = React.useState(false);

    useEffect(() => {
        var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"))
        console.log('logged', logged)
        setLogtoken(logged)
        getData()
    },[])

    const getData = () => {
        setLoading(true)
        var token = JSON.parse(localStorage.getItem("token"))
         fetch(global.url + "myOrdersfrontend", {
             method: "POST",
             headers: {
                 'Content-Type': 'application/json',
                 'APPKEY': 'erp6129',
                 'Authorization' : 'Bearer ' + token 
             },
         
         })
         .then((response)=> response.json())
         .then((responseJson)=>{
            setLoading(false)
             if(responseJson.error == false){
                setSource(responseJson.data)
             }
             else{
                setSource([])
             }
            console.log("order response", responseJson)
             
         })
         .catch((error)=>{
             console.log(error)
         })
    }

    const deleteOrder = (id) => {
        var token = JSON.parse(localStorage.getItem("token"))
        var data ={
            "orderid" : id
        }
         fetch(global.url + "CancelMyOrder", {
             method: "POST",
             headers: {
                 'Content-Type': 'application/json',
                 'APPKEY': 'erp6129',
                 'Authorization' : 'Bearer ' + token 
             },
             body: JSON.stringify(data)
         })
         .then((response)=> response.json())
         .then((responseJson)=>{
             if(responseJson.error == false){
                setOpen(true)
                setMessage(responseJson.message)
                getData()
             }
             else{
                setOpen(true)
                setMessage(responseJson.message)
                getData()
             }
            console.log("order cancel response", responseJson)
             
         })
         .catch((error)=>{
             console.log(error)
         })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

      const logoutClick = () =>{
        setLoader(true)
        var token =  JSON.parse(localStorage.getItem("token"))
        fetch(global.url + "logout", {
            method: "POST",
            headers: {
                'Content-Type' : 'application/json',
                'APPKEY' : 'erp6129',
                'Authorization' : 'Bearer ' + token 
            },
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("logout", responseJson)
            setLoader(false)
            if(responseJson.error == false){
                localStorage.setItem("isAuthenticatedfront", false);
                localStorage.removeItem("token")
                localStorage.removeItem("userName")
                localStorage.removeItem("persist:root")
                localStorage.removeItem("userdata")
                history.push('/')
                window.location.reload()

            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    return(
        <div>
            <div className='cartTop'>
                <Container>
                    <div>
                        <p style={{fontSize: 23, fontWeight: 'bold'}}>My Orders</p>
                    </div>
                    <Row>
                        <Col xs={12} md={4} lg={3}>
                            <div className="profilebox">
                                <a onClick={() => history.push('/profile')}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <div style={{padding: 15, paddingBottom: 1}}>
                                            <p style={{fontSize: 14, cursor: 'pointer', fontWeight: 500}}><FaUserAlt color="#000" size={13} style={{marginRight: 7, marginTop: -2}} /> My Profile</p>
                                        </div>
                                        <div style={{height: 53, width: 5, backgroundColor: '#d9d9d9'}}></div>
                                    </div>
                                </a>
                                <div style={{width: '100%', height: 1, backgroundColor: '#d9d9d9'}}></div>
                                <a>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <div style={{padding: 15, paddingBottom: 1}}>
                                            <p style={{fontSize: 14, cursor: 'pointer', fontWeight: 500}}><FaListAlt color="#000" size={15} style={{marginRight: 5, marginTop: -2}} /> My Orders</p>
                                        </div>
                                        <div style={{height: 53, width: 5, backgroundColor: '#286bc7'}}></div>
                                    </div>
                                </a>
                                <div style={{width: '100%', height: 1, backgroundColor: '#d9d9d9'}}></div>
                                <a onClick={() => history.push('/wishlists')}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <div style={{padding: 15, paddingBottom: 1}}>
                                            <p style={{fontSize: 14, cursor: 'pointer', fontWeight: 500}}><AiFillHeart color="#000" size={15} style={{marginRight: 5, marginTop: -2}} /> My Wishlist</p>
                                        </div>
                                        <div style={{height: 53, width: 5, backgroundColor: '#d9d9d9'}}></div>
                                    </div>
                                </a>
                                <div style={{width: '100%', height: 1, backgroundColor: '#d9d9d9'}}></div>
                                {logtoken ? 
                                    <a onClick={logoutClick}>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div style={{padding: 15, paddingBottom: 1}}>
                                                <p style={{fontSize: 14, cursor: 'pointer', fontWeight: 500}}><MdLogout color="#000" size={16} style={{marginRight: 5, marginLeft: 3, marginTop: -2}} /> Logout</p>
                                            </div>
                                            <div style={{height: 53, width: 5, backgroundColor: '#d9d9d9'}}></div>
                                        </div>
                                    </a>:
                                    <a onClick={() => history.push('/login')}>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div style={{padding: 15, paddingBottom: 1}}>
                                                <p style={{fontSize: 14, cursor: 'pointer', fontWeight: 500}}><FiLogIn color="#000" size={16} style={{marginRight: 7, marginTop: -2}} /> Login</p>
                                            </div>
                                            <div style={{height: 53, width: 5, backgroundColor: '#d9d9d9'}}></div>
                                        </div>
                                    </a>
                                }
                            </div>
                        </Col>
                        <Col xs={12} md={8} lg={9}>
                            {/* my orders */}
                            
                            <div>
                                {loading ? <Loader /> :
                                <div>
                                    {source.length == 0 ? <p style={{fontSize: 14, fontWeight: 500,textAlign: "center", }}> No Orders </p>:
                                    <div>
                                        {source.map((item) =>(
                                            <a style={{cursor: 'pointer'}}  >
                                                <div className='myordersdiv'>
                                                    <Row>
                                                        <Col xs={12} md={3} lg={2}>
                                                            <div style={{display: 'flex', justifyContent: "center"}}>
                                                                {
                                                                    item.image == null ?
                                                                    <img alt="fetch" src={img} className="order-img" style={{width: '100%', height: 100}} /> :  
                                                                    <img alt="fetch" src={global.images + "ecommerce_photos/" + item.image} className="order-img" style={{width: '100%', height: 100}} />
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} md={9} lg={10}>
                                                            <div className='order-paradiv'>
                                                                <p style={{marginBottom: 'unset', fontSize: 14, fontWeight: 500}}>{item.productname}</p>
                                                                <p style={{fontSize: 12, fontWeight: 500, color: '#7a7a7a', marginBottom: 'unset'}}>Order Id : {item.invoice}</p>
                                                                <p style={{fontSize: 12, fontWeight: 500, color: '#7a7a7a',marginBottom: 'unset'}}>Color and Size : {item.color} - {item.size}</p>
                                                                <p style={{fontSize: 12, fontWeight: 500, color: '#7a7a7a'}}>Quantity : {item.quantity}</p>
                                                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                                    <div>
                                                                        {
                                                                            item.cancelled == true ? 
                                                                            <p style={{fontSize: 14, fontWeight: 500, color: '#7a7a7a'}}>Cancelled</p> :
                                                                            item.delivered == true ? 
                                                                            <p style={{fontSize: 14, fontWeight: 500, color: '#7a7a7a'}}>Delivered</p> :
                                                                            item.shipped == true ? 
                                                                            <p style={{fontSize: 14, fontWeight: 500, color: '#7a7a7a'}}>Shipped</p>:
                                                                            <p style={{fontSize: 14, fontWeight: 500, color: '#7a7a7a'}}>Ordered</p>
                                                                        }
                                                                    </div>
                                                                    {item.cancelled == false ?
                                                                    <button style={{cursor: 'pointer', background: 'none', border: 'none'}} onClick={() => history.push('/trackorder/' + item.orderid)}>
                                                                        <p style={{fontSize: 14, fontWeight: 500, color: '#7a7a7a'}}>Track Order</p>
                                                                    </button>: null}
                                                                    {item.cancelled == false ?
                                                                    <button style={{cursor: 'pointer', background: 'none', border: 'none'}} onClick={() => deleteOrder(item.orderid)}>
                                                                        <p style={{fontSize: 14, fontWeight: 500, color: 'red'}}>Order Cancel</p>
                                                                    </button>: null }
                                                                </div>
                                                                
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </a>
                                        ))}
                                    </div>}
                                </div>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal:'center' }}
                open={open} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {message}
                    </Alert>
            </Snackbar>
        </div>
    );
}

export default Order;