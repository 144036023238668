import React, {useEffect} from 'react';
import Slider from "react-slick";
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import {Container, Row, Col, Button } from "react-bootstrap";
import {useHistory} from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';


function Category(props){
    let history = useHistory();

    useEffect(() => {
        console.log("props.categorylist", props.categorylist)
    }, [])
  
    return(
        <Row>
            {
                props.categoryloading ? 
                    <Row>
                        <Col xs={6} md={3} lg={2} style={{marginTop: 5}}>
                            <div>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <Skeleton sx={{ width: 130, height: 120 }} animation="wave" variant="circular" />
                                </div>
                                <Skeleton variant="text" />
                            </div>
                        </Col>
                        <Col xs={6} md={3} lg={2} style={{marginTop: 5}}>
                            <div>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <Skeleton sx={{ width: 130, height: 120 }} animation="wave" variant="circular" /> 
                                </div>
                                <Skeleton variant="text" />
                            </div>
                        </Col>
                        <Col xs={6} md={3} lg={2} style={{marginTop: 5}}>
                            <div>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <Skeleton sx={{ width: 130, height: 120 }} animation="wave" variant="circular" />
                                </div>
                                <Skeleton variant="text" />
                            </div>
                        </Col>
                        <Col xs={6} md={3} lg={2} style={{marginTop: 5}}>
                            <div>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <Skeleton sx={{ width: 130, height: 120 }} animation="wave" variant="circular" />
                                </div>
                                <Skeleton variant="text" />
                            </div>
                        </Col>
                        <Col xs={6} md={3} lg={2} style={{marginTop: 5}}>
                            <div>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <Skeleton sx={{ width: 130, height: 120 }} animation="wave" variant="circular" />
                                </div>
                                <Skeleton variant="text" />
                            </div>
                        </Col>
                        <Col xs={6} md={3} lg={2} style={{marginTop: 5}}>
                            <div>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <Skeleton sx={{ width: 130, height: 120 }} animation="wave" variant="circular" />
                                </div>
                                <Skeleton variant="text" />
                            </div>
                        </Col>
                    </Row>
                :
                props.categorylist.map((item) => (
                    <Col xs={6} md={3} lg={2} style={{marginTop: 5}}>
                        <a onClick={() => history.push('/product/' + item.cat_id + "/category")} style={{cursor: "pointer"}}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <img alt="fetch" src={global.images + 'ecommerce_photos/' + item.cat_image}  style={{width: 120, height: 120}} /> 
                            </div>
                            <p style={{textAlign: 'center', fontWeight: 'bold', marginTop: 10, fontSize: 16}}>{item.cat_name}</p>
                        </a>
                    </Col>
                ))
            }
        </Row>
    );
}
export default Category;