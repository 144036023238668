import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Form} from "react-bootstrap";
import { FaMobileAlt, FaListAlt } from "react-icons/fa";
import {FiSearch, FiLogIn} from "react-icons/fi"
import { MdDeliveryDining } from "react-icons/md";
import { RiCustomerService2Fill } from "react-icons/ri";
import { HiMenuAlt1 } from "react-icons/hi";
import { AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import logo from '../../Assets/Images/FetchLogo.svg';
import cartimage from '../../Assets/Images/cart.svg';
import {useHistory} from 'react-router-dom';
import profile from '../../Assets/Images/profile.svg';
import Loader from '../../Components/Loader/Loader';

import { AiFillHome, } from "react-icons/ai";
import { FaShoppingCart, FaAddressBook, FaInfo, FaSignOutAlt } from "react-icons/fa";


import { connect } from "react-redux";

function Header(props) {
    let history = useHistory();
    const [cartCount, setCartCount] = useState(0);
    const [total, setTotal] = useState(0);
    const [cartQty, setCartQty] = useState(0);
    const [cartTotal, setCartTotal] = useState(0);
    const [logToken, setLogtoken] = useState('');
    const [loader, setLoader] = React.useState(false);

    useEffect(() => {  
        var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"))
        setLogtoken(logged)
      },[])

    const logout = () => {
        setLoader(true)
        var token =  JSON.parse(localStorage.getItem("token"))
        fetch(global.url + "logout", {
            method: "POST",
            headers: {
                'Content-Type' : 'application/json',
                'APPKEY' : 'erp6129',
                'Authorization' : 'Bearer ' + token 
            },
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("logout", responseJson)
            setLoader(false)
            if(responseJson.error == false){
                localStorage.setItem("isAuthenticatedfront", false);
                localStorage.removeItem("token")
                localStorage.removeItem("userName")
                localStorage.removeItem("userdata")
                localStorage.removeItem("persist:root")
               
                history.push('/')
                window.location.reload()
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    return(
        <div className={props.toggle ? 'mobilemenu1' : 'mobilemenu'}>
            <ul className='listtop'>
                <li><a href='/' style={{color: 'unset', textDecoration: 'unset'}}><AiFillHome className='menulistic'/> Home</a></li>
                <li><a href='/cart' style={{color: 'unset', textDecoration: 'unset'}}><FaShoppingCart className='menulistic'/>My Cart</a></li>
                <li><a href='/profile' style={{color: 'unset', textDecoration: 'unset'}}><FaAddressBook className='menulistic'/> Address</a></li>
                <li><a href='/' style={{color: 'unset', textDecoration: 'unset'}}><FaInfo className='menulistic'/> About Us</a></li>
                {logToken ?
                    <li>
                        {loader ? <Loader /> :
                        <a onClick={() => logout()} style={{color: 'unset', textDecoration: 'unset'}}><FaSignOutAlt className='menulistic' /> Logout</a>}</li>:
                    <li><a href='/login' style={{color: 'unset', textDecoration: 'unset'}}><FaSignOutAlt className='menulistic' /> Login</a></li>
                }
            </ul>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
      cart: state.shop.cart,
      refreshitem: state.shop.refreshitem
    };
  };
  

export default connect(mapStateToProps)(Header);;