import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import logo from '../../Assets/Images/FetchLogo.svg';
import {useHistory} from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../../Components/Loader/Loader';
import { authentication } from '../../firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { connect } from "react-redux";
import {
    refresh
  } from "../../redux/Shopping/shopping-actions";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

function Guest({cart,refresh}){
    let history = useHistory();
    const countryCode = '+974';
    const vertical = "top"
    const horizontal = "center"
    const [mobile, setMobile] = useState(countryCode);
    const [otp, setOtp] = useState("");
    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrormessage] = React.useState(false);
    const [loader, setLoader] = React.useState(false);
    const [modal, setModal] = useState(false);

    const mobChange = (e) => {
        const value = e.target.value
        setMobile(value);
    }

    const otpChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, '')
        setOtp(value);
    }

    const generateRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size' : 'invisible',
            'callback': (response) => {
                
            },
        }, authentication)
    }

    const onSubmit = () => {
        setLoader(true)
        generateRecaptcha()
        let appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(authentication, mobile, appVerifier)
        .then(confirmationResult => {
            window.confirmationResult = confirmationResult;
            setLoader(false)
            setModal(true)
            setOpen(true)
            setErrormessage('OTP has been sent to your mobile number')
        }).catch((error) => {
            alert('Invalid Mobile number') 
            setLoader(false)
        })
    }

    const otpSubmit = () => {
        setLoader(true)
   
            let confirmationResult = window.confirmationResult;
            confirmationResult.confirm(otp).then(result => {
                const user = result.user;
                // console.log('user', user)
                // alert('successfully registered')
                setLoader(false)
                setOpen(true)
                setErrormessage('Successfully registered')
                otpregister(user.phoneNumber)
            }).catch((error) => {
                console.log(error)
                setLoader(false)
                alert('invalid varification code') 
            })
    }

    const otpregister = (mobile) => {
        var data = {
            "mobno" : mobile,
        }
        console.log("data", data)
        fetch(global.url + "auth/Mobotp", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            console.log("response otp", responseJson)  
            if(responseJson.error == false){
                var data = responseJson.data
                var data1 = data.user_data
                localStorage.setItem("isAuthenticatedfront", true);
                localStorage.setItem("token", JSON.stringify(data.api_token));
                localStorage.setItem("userName", JSON.stringify(data1.user_name));
                localStorage.setItem("userdata", JSON.stringify(data1));

               cartItems()
               
                history.push('/');
                setModal(false)
                window.location.reload()
            }else{
                localStorage.setItem("isAuthenticatedfront", false);
                setOpen(true)
                setErrormessage(responseJson.message)
            }
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const cartItems = () => {
        console.log('check')
        var token = JSON.parse(localStorage.getItem("token"))
        var data = {
            'data': cart,
        }
        console.log("data1233", data)
        fetch(global.url + "frontEndaddCart", {
            method: "POST",
            headers: {
                'Content-Type' : 'application/json',
                'APPKEY' : 'erp6129',
                'Authorization' : 'Bearer ' + token 
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("responseJson", responseJson)
        //   refresh("")
        })
        .catch((error)=>{
            console.log(error)
        })
    }


    const handleClose = (event, reason) => {
        if(reason === 'clickaway'){
            return;
        }
        setOpen(false);
    }

    return(
        <div className="App">
            <Container fluid>
                <Row>
                    <Col xs={12} style={{marginTop: 100}}> 
                        <div className="login-box">
                            <div className="box-inside">
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 10}}>
                                    <img alt="fetch" src={logo} />
                                </div>
                                <p className='loginpara' style={{fontSize: 20}}>Guest Login</p>
                                <div style={{marginTop: 30}}>
                                    <Form>
                                        <Form.Group className="mb-1" controlId="formBasicEmail">
                                            <Form.Control 
                                                type="text"
                                                className='shadow12' 
                                                value={mobile}
                                                onChange={mobChange} 
                                                placeholder='Enter Your Mobile Number' />
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div style={{marginTop: 50}}>
                                    {loader ? 
                                        <Loader />:
                                        <Button onClick={onSubmit} variant="primary" style={{boxShadow: 'none', border: 'none', backgroundColor: '#286bc7', width: '100%'}}>Send OTP</Button>
                                    }
                                </div> 
                                <div style={{marginTop: 10}}>
                                    <a onClick={() => history.push('/')}><p style={{textAlign: 'center', fontSize: 14, fontWeight: 500, color: '#286bc7', cursor: 'pointer'}}>Home</p></a>
                                </div>
                                <div id="recaptcha-container"></div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal
                size="md"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{padding: 25}}>
                        <div style={{marginTop: -15}} className="box-inside">
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 10}}>
                                <img alt="fetch" src={logo} />
                            </div>
                            <p className='loginpara' style={{fontSize: 20}}>OTP</p>
                            <div style={{marginTop: 30}}>
                                <Form>
                                    <Form.Group className="mb-1" controlId="formBasicEmail">
                                        <Form.Control 
                                            type="text"
                                            className='shadow12' 
                                            value={otp}
                                            onChange={otpChange} 
                                            placeholder='Enter OTP Number' />
                                    </Form.Group>
                                </Form>
                            </div>
                            <div style={{marginTop: 50}}>
                                {loader ? 
                                    <Loader />:
                                    <Button onClick={otpSubmit} variant="primary" style={{boxShadow: 'none', border: 'none', backgroundColor: '#286bc7', width: '100%'}}>Submit</Button>
                                }
                            </div> 
                            <div id="recaptcha-container"></div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Snackbar anchorOrigin={{vertical, horizontal}} open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </div>
    )
} 

const mapStateToProps = (state) => {
    return {
        cart: state.shop.cart,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      refresh: (item) => dispatch(refresh(item))
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(Guest);