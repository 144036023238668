import React from 'react';
import done from '../../Assets/Images/ordered.png';
import {Container, Row, Col, Button, Form } from "react-bootstrap";
import {useHistory} from 'react-router-dom';
import logo from '../../Assets/Images/FetchLogo.svg';

function Summary() {
    let history = useHistory();
    return(
        <div style={{ marginBottom: 30}}>
             <div className="header2">
                <Container>
                    <Row>
                        <Col xs={5} lg={3}>
                            <div style={{display: 'flex'}}>
                                <a href="/" style={{cursor: 'pointer'}}>
                                    <img alt="fetch" src={logo}  className='fetchlogo' />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 50}}>
                    <img src={done} style={{width: 200, height: 150}} />
                </div>
                <p style={{textAlign: "center", fontSize: 20, fontWeight: 'bold', marginTop: 20}}>Your Order Confirmed !!!</p>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
                    <button onClick={() =>  history.push('/')} type="button" class="btn btn-primary" style={{backgroundColor: '#286bc7', border: 'none'}}>Back To Home</button>
                </div>
            </Container>
        </div>
    );
}

export default Summary;