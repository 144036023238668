import React, {useState, useEffect} from 'react';
import './header.css';
import {Container, Row, Col, Form} from "react-bootstrap";
import { FaMobileAlt, FaListAlt } from "react-icons/fa";
import {FiSearch, FiLogIn} from "react-icons/fi"
import { MdDeliveryDining } from "react-icons/md";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import {BsArrowReturnRight} from "react-icons/bs"
import logo from '../../Assets/Images/FetchLogo.svg';
import cart from '../../Assets/Images/cart.svg';
import Searchic from '../../Assets/Images/searchic.svg';
import {useHistory, useLocation} from 'react-router-dom';
import profile from '../../Assets/Images/profile.svg';
import Carticocomponent from './Carticocomponent';
import Categorymenu from './categorymenu';

function Header(props) {
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");
    const [userData, setUserData] = useState();
    const [logtoken, setLogtoken] = useState('');
    const [username, setUsername] = useState('')

    const [mobsearch, setMobsearch] = useState(false)

    const [catmenu, setCatmenu] = useState(false);
    const location = useLocation();
    let history = useHistory();

    const handleSearch = (event) => {
        const searchWord = event.target.value;
        // console.log('searchWord', searchWord)
        setWordEntered(searchWord);
        var data = {
            "search_product" : wordEntered,
            "limit" : 15
        }
        fetch(global.url + "searchProducts",{
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            // console.log("search response", responseJson)
            if(responseJson.error == false){
                setFilteredData(responseJson.data)
            } else {
                setFilteredData([])
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }
   
    const clearInput = () => {
        setFilteredData([]);
        setWordEntered("");
    };


      const submenuOpen = () => {
        setCatmenu(!catmenu)
      }

      useEffect(() => {
        setCatmenu(false)
    },[location.pathname])

    useEffect(() => {
        var logged =  JSON.parse(localStorage.getItem("isAuthenticatedfront"))
        var username = JSON.parse(localStorage.getItem("userName"))
        setLogtoken(logged)
        setUsername(username)
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    },[location.pathname])

    const listClick = (value) => {
        setFilteredData([])
        setWordEntered("")
        history.push('/product/' + value.products_id + "/subcategory")
    }

    const onMobileclick = () => {
        props.parentCallback()
    }
    return(
        <div className='header'>
            <div className='header1'>
                <Container>
                    <Row>
                        <Col md={3}>
                            <a style={{display: 'flex'}}>
                                <FaMobileAlt color="#ffde09" size={16} style={{marginTop: 3, marginRight: 10}}/><p className='header1-para'>Download The App Now</p>
                            </a>
                        </Col>
                        <Col md={6}>
                            <a style={{display: 'flex'}}>
                                <MdDeliveryDining color="#ffde09" size={22} style={{marginTop: 2, marginRight: 10}}/><p className='header1-para'>Free Home Delivery Starting At Above 200 QAR</p>
                            </a>
                        </Col>
                        <Col md={3}>
                            <a style={{display: 'flex', float: 'right'}}>
                                <RiCustomerService2Fill color="#ffde09" size={20} style={{marginTop: 2, marginRight: 10}}/><p className='header1-para'>24/7 Customer Care</p>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="header2">
                <Container>
                    <Row>
                        <Col xs={5} lg={3}>
                            <div style={{display: 'flex'}}>
                                {/* <div>
                                    <div style={{position: 'relative', marginTop: 10, cursor: 'pointer'}} onClick={() => submenuOpen()}>
                                        <a>
                                            <HiMenuAlt1 color="#286bc7" size={28} style={{marginTop: 2, marginRight: 10}}/>
                                        </a>
                                    </div>
                                    <Categorymenu catmenu = {catmenu}/>
                                </div> */}
                                <FiMenu onClick={() => onMobileclick()}  className='desktop-display' style={{width: 25, height: 25, color: '#286bc7', marginRight: 8, marginTop:3, cursor: 'pointer'}}/>

                                <a href="/" style={{cursor: 'pointer'}}>
                                    <img alt="fetch" src={logo}  className='fetchlogo' />
                                </a>
                            </div>
                        </Col>
                        <Col className="searchbar"  xs={{span: 12, order: 3 }} lg={{span: 5, order: 2}}>
                            <div style={{position: 'relative'}}>
                                <div className='searchdiv'>
                                    <Form>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text" onChange={handleSearch} value={wordEntered} placeholder="What are you looking for?" 
                                            className="header-textinput" style={{borderColor: '#286bc7'}} />
                                        </Form.Group>
                                    </Form>
                                    {filteredData.length == 0 ? (
                                            <FiSearch color="#286bc7" size={20} style={{position: 'absolute', top: 7, right: 10}}/>
                                    ) : (
                                            <AiOutlineClose onClick={clearInput} color="#000" size={17} style={{position: 'absolute', top: 9, right: 10}}/>
                                    )}
                                </div>
                                {filteredData.length == 0 ? null:
                                    <div className="dataResult">
                                    {filteredData.map((value, key) => {
                                        return (
                                        <a onClick={() => listClick(value)} className="searchItem" target="_blank">
                                            <div style={{marginTop:10, display: 'flex'}}>
                                                <BsArrowReturnRight color="#000" size={14} style={{marginLeft: 20, marginTop: 2}}/>
                                                <p style={{fontSize: 12, marginLeft: 10}}>{value.product_name}</p>
                                            </div>
                                            <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                                        </a>
                                        );
                                    })}
                                    </div>
                                }
                            </div>
                        </Col>
                        <Col xs={{ span: 7, order: 2 }} lg={{span:4, order: 3 }}>
                            <div className="responsive-cart" style={{display: 'flex', float: 'right'}}>
                                <div>
                                    {logtoken ?
                                        <a style={{cursor: 'pointer'}} className="mobile-display">
                                            <p style={{fontSize: 16, fontWeight: 'bold', marginTop: 13, color: '#286bc7'}}>{username}</p>
                                        </a>:
                                        <a onClick={() => history.push('/login')} style={{cursor: 'pointer'}} className="mobile-display">
                                            <p style={{fontSize: 16, fontWeight: 'bold', marginTop: 13, color: '#286bc7'}}>Login</p>
                                        </a>
                                    }
                                </div>
                                {logtoken ?
                                    <a style={{cursor: 'pointer'}} href="/profile">
                                        <div>
                                            <img alt="fetch" src={profile} className="profileicon" />
                                        </div>
                                    </a>:
                                    <a  style={{cursor: 'pointer'}}  href="/login">
                                        <div>
                                            <img alt="fetch" src={profile} className="profileicon"  />
                                        </div>
                                    </a>
                                }
                                <Carticocomponent />

                                {/* mobile search bar */}
                                <div className='desktop-display'>
                                    <a style={{cursor: 'pointer'}} onClick={() => setMobsearch(!mobsearch)}>
                                        <div>
                                            <img alt="fetch" src={Searchic} className="profileicon" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className={mobsearch ?  'mobsearchdiv1' : 'mobsearchdiv'}>
                            <div className='searchdiv1'>
                                <Form style={{height: '100%'}}>
                                    <Form.Group className="mb-3" style={{height: '100%'}}>
                                        <Form.Control type="text" onChange={handleSearch} value={wordEntered} placeholder="What are you looking for?" 
                                        className="header-textinput" style={{borderColor: '#286bc7', height: '100%'}} />
                                    </Form.Group>
                                </Form>
                                
                                    <AiOutlineClose onClick={() => {setMobsearch(!mobsearch) 
                                    setFilteredData([])
                                     setWordEntered("")}} color="#000" size={17} style={{position: 'absolute', top: 16, right: 10, width:20, height:20}}/>
                            </div>
                            {filteredData.length == 0 ? null:
                                <div className="dataResult">
                                {filteredData.map((value, key) => {
                                    return (
                                    <a onClick={() => listClick(value)} className="searchItem" target="_blank">
                                        <div style={{marginTop:10, display: 'flex'}}>
                                            <BsArrowReturnRight color="#000" size={14} style={{marginLeft: 20, marginTop: 2}}/>
                                            <p style={{fontSize: 12, marginLeft: 10}}>{value.product_name}</p>
                                        </div>
                                        <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                                    </a>
                                    );
                                })}
                                </div>
                            }
                        </div>
                </Container>
            </div>
        </div>
    );
}

export default Header;