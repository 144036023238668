import './NewArrival.css';
import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Button } from "react-bootstrap";
import Slider from "react-slick";
import { slice, concat } from 'lodash';
import { AiOutlineReload } from "react-icons/ai";
import product1 from '../../Assets/Images/products/shoe1.png';
import product2 from '../../Assets/Images/products/shoe2.png';
import product3 from '../../Assets/Images/products/shoe3.png';
import {useHistory} from 'react-router-dom';
import {
    addToCart,
  } from "../../redux/Shopping/shopping-actions";
import { connect } from "react-redux";

import Product from './product';


function NewArrival(props){
    return(
        <div>
            <Row>
                {props.data.map((product) => (
                        <Col xs={6} md={3} lg={2} className="spacereduce">
                            <Product key={product.id} product={product} />
                        </Col>
                    ))}
            </Row>
        </div>
    );
}




export default NewArrival;