import React, {useEffect} from 'react';
import Slider from "react-slick";
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import {Container, Row, Col, Button } from "react-bootstrap";
import {useHistory} from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';


function Category(props){
    let history = useHistory();
    useEffect(() => {
        console.log("props.gridcategorylist", props.categorylist)
    }, [])
  
    return(
        <Row>
            <div className="">
                    <div class="luxury-experiences__grid">
                        {
                            props.categorylist.map((item ,index) => {
                                return(
                                    index == 12 ? null: 
                                    item.cat_image == "noproductimage.jpg" ? null :
                                    <a target="_blank" onClick={() => history.push('/product/' + item.cat_id + "/category")} class="luxury-experiences__grid-item img-hover-zoom" style={{cursor: 'pointer'}}>
                                            <img alt="fetch" class="lazy-image luxury-experiences__grid-image lazy-image-loaded" sizes="100vw" src={global.images + 'ecommerce_photos/' + item.cat_image}/>
                                            <div class="luxury-experiences__grid-item-title">{item.cat_name}</div>
                                    </a>
                                )}
                            )
                        }   
                    </div>
                </div>
        </Row>
    );
}
export default Category;