import React from 'react';
import './Footer.css';
import {Row, Col, Container} from "react-bootstrap";
import { MdOutlineSecurity, MdHeadsetMic } from "react-icons/md";
import { GiRoundStar } from "react-icons/gi";
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaYoutube, FaShoppingBag } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";

function Footer() {
    return(
        <div style={{marginTop: 70}}>
            <Container>
                <Row>
                    <Col xs={12} sm={6} md={3}>
                        <p style={{textAlign: 'center'}}><MdOutlineSecurity color="#000" size={25}/></p>
                        <p style={{textAlign: 'center', fontSize: 16, fontWeight: 600}}>Secure Payments</p>
                        <p style={{textAlign: 'center', fontSize: 14, color: '#6e6e6e'}}>Your money is yours! All refunds come with no question asked quarantee</p>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <p style={{textAlign: 'center'}}><MdHeadsetMic color="#000" size={25}/></p>
                        <p style={{textAlign: 'center', fontSize: 16, fontWeight: 600}}>24x7 Help</p>
                        <p style={{textAlign: 'center', fontSize: 14, color: '#6e6e6e'}}>Need Help? You can also talk to us on +974 77647784 to resolve your query</p>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <p style={{textAlign: 'center'}}><GiRoundStar color="#000" size={25} /></p>
                        <p style={{textAlign: 'center', fontSize: 16, fontWeight: 600}}>100% Assurance</p>
                        <p style={{textAlign: 'center', fontSize: 14, color: '#6e6e6e'}}>At Fetch, we provide 100% assurance. If you have any issues, your money is immediately refunded</p>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <p style={{textAlign: 'center'}}><FaShoppingBag color="#000" size={25} /></p>
                        <p style={{textAlign: 'center', fontSize: 16, fontWeight: 600}}>Shop on the App</p>
                        <p style={{textAlign: 'center', fontSize: 14, color: '#6e6e6e'}}>Comming Soon</p>
                    </Col>
                </Row>
                <div style={{width: '100%', backgroundColor: '#d2d2d2', height: 1}}></div>
                <Row>
                    {/* <Col xs={6} md={6} lg={2}>
                        <div style={{marginTop: 30}}>
                            <p style={{fontSize: 14, fontWeight: 'bold', color: '#6e6e6e'}}>COMPANY</p>
                            <div>
                                <li className="footer-li">Submit A Review</li>
                                <li className="footer-li">About Us</li>
                                <li className="footer-li">Press Release</li>
                                <li className="footer-li">See Map</li>
                            </div> 
                        </div>
                    </Col> */}
                    <Col xs={6} md={6} lg={4}>
                        <div style={{marginTop: 30}}>
                            <p style={{fontSize: 14, fontWeight: 'bold', color: '#6e6e6e'}}>MY ACCOUNT</p>
                            <div>
                                <ul style={{paddingLeft: 'unset'}}>
                                    <a href='/cart' style={{color: 'unset', textDecoration: 'unset'}}><li className="footer-li">My Account</li></a>
                                    <a href='/profile' style={{color: 'unset', textDecoration: 'unset'}}><li className="footer-li">Shopping Cart</li></a>
                                    {/* <a href=''><li className="footer-li">Checkout</li></a>
                                    <a href=''><li className="footer-li">Careers</li></a> */}
                                </ul>
                            </div> 
                        </div>
                    </Col>
                    {/* <Col xs={6} md={6} lg={3}>
                    <div style={{marginTop: 30}}>
                            <p style={{fontSize: 14, fontWeight: 'bold', color: '#6e6e6e'}}>INFORMATION</p>
                            <div>
                                <li className="footer-li">Our Blog</li>
                                <li className="footer-li">Warranty & Return Policy</li>
                                <li className="footer-li">Privacy Policy</li>
                                <li className="footer-li">Term And Conditions</li>
                                <li className="footer-li">FAQ</li>
                                <li className="footer-li">Contact Us</li>
                            </div> 
                        </div>
                    </Col> */}
                    <Col xs={6} md={6} lg={4}>
                        <div style={{marginTop: 30}}>
                            <p style={{fontSize: 14, fontWeight: 'bold', color: '#6e6e6e'}}>HELP CENTER</p>
                            <p style={{fontSize: 13, color: '#6e6e6e'}}>help@fetch.com</p>
                            <p style={{fontSize: 14, fontWeight: 'bold', color: '#6e6e6e'}}>EMAIL SUPPORT</p>
                            <p style={{fontSize: 13, color: '#6e6e6e'}}>info@fetch.com</p>
                            <p style={{fontSize: 14, fontWeight: 'bold', color: '#6e6e6e'}}>SUPPORT NUMBER</p>
                            <p style={{fontSize: 13, color: '#6e6e6e'}}>+974 77647784</p>
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={4} style={{marginTop: 30}}>
                        <p style={{fontSize: 14, fontWeight: 'bold', textAlign: 'center', color: '#6e6e6e'}}>CONNECT WITH SOCIAL MEDIA</p>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div>
                                <a href="https://www.facebook.com/pages/category/Shopping---retail/Fetch-Qatar-Online-107401807581678/" target="_blank"><FaFacebookF color="#6e6e6e" size={15} style={{}}/></a>
                                <a href="#"><AiOutlineTwitter color="#6e6e6e" size={18} style={{marginLeft: 20, marginTop: 2}}/></a>
                                <FaLinkedinIn color="#6e6e6e" size={15} style={{marginLeft: 20}}/>
                                <a href="https://www.instagram.com/fetch_qatar/?hl=en" target="_blank"><FaInstagram color="#6e6e6e" size={15} style={{marginLeft: 20}}/></a>
                                {/* <FaYoutube color="#6e6e6e" size={18} style={{marginLeft: 20, marginTop: 2}}/> */}
                            </div>
                        </div>
                    </Col>
                </Row>
                <p className="footer-copyright">Copyright © 2021 Fetch. All rights reserved.</p>
            </Container>
        </div>
    );
}

export default Footer;