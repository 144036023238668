import './Product.css';
import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Button } from "react-bootstrap";
import Slider from "react-slick";
import { AiOutlineClose } from "react-icons/ai";
import {useHistory} from 'react-router-dom';
import {
    addToCart,
    refresh
  } from "../../redux/Shopping/shopping-actions";
import { connect } from "react-redux";
import Snackbar from '@mui/material/Snackbar';
import img from '../../Assets/Images/noimages.png';

import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const data = [{}, {}, {}, {}, {}, {}, {}, {}]

function Products({product,addToCart, cart, refresh}){
    let history = useHistory();
    const LENGTH = 18;
    const DATA = [ ...Array(LENGTH).keys() ];
    const LIMIT = 6;
    const [open, setOpen] = React.useState(false);
    const [logtoken, setLogtoken] = useState('');
    const [message, setMessage] = useState('');
    const [cartView, setCartview] = React.useState(false)
    const [cartCount, setCartCount] = useState(0);
    const [total, setTotal] = useState(0);
    const [cartList, setCartList] = useState([])
    const [cartQty, setCartQty] = useState('');
    const [cartTotal, setCartTotal] = useState('');

    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        // autoplay: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1
      };

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

      const toClick = (product) => {
        console.log("product", product)
        addToCart(product)
        setOpen(true)
        viewCart()
      }

      const contHome = () => {
        history.push('/')
        setCartview(false)
    }
    const closeviewCart = () => {
      setCartview(false)
  }

  useEffect(() => {
    let count = 0;
    var total = 0;
    cart.forEach((item) => {
      count += item.qty;
      total += item.qty * item.id.pb_onlineprice;
    })
    setCartCount(count);
    setTotal(total);
   
  }, [cart, cartCount.toExponential]);

  const viewCart = () => {
    setCartview(true)
}

      const AddCart = (id) => {
        console.log("id", id)
        var token = JSON.parse(localStorage.getItem("token"))
        var data = {
            "productid" : id,
            "quantity" : 1
        }
        console.log("dataarr", data)
        fetch(global.url + "addCartfrontend", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
              'APPKEY': 'erp6129',
              'Authorization' : 'Bearer ' + token 
          },
          body: JSON.stringify(data)
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        
          if(responseJson.error == false){
            setOpen(true)
            setMessage(responseJson.message)
            refresh("")
            viewCart()
            getCartData()
          }
          else{
            setOpen(true)
            setMessage(responseJson.message)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
      }

      const getCartData = () => {
        var token = JSON.parse(localStorage.getItem("token"))
        fetch(global.url + "displayCartfrontend", {
            method: "POST",
             headers: {
                 'Content-Type': 'application/json',
                 'APPKEY': 'erp6129',
                 'Authorization' : 'Bearer ' + token 
             },
        })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("responseJson cart123", responseJson)
          if(responseJson.error == false){
            setCartList(responseJson.data);
            setCartQty(responseJson.cart_count)
            setCartTotal(responseJson.total)
          } else {
            setCartList([])
            setCartQty(0)
            setCartTotal(0)
          }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

      useEffect(() => {  
        var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"))
        setLogtoken(logged)
        getCartData()
      },[])

      useEffect(() => {
        console.log('product', product)
      }, [product])

      useEffect(() => {  
        setMessage("Add To Cart")
      },[cart])

    return(
        <div style={{marginTop: 10}}>
          <div className='newarrival-img-border'>
              <a onClick={() => history.push('/details/' + product.productid + '/' + product.batchid )} style={{textDecoration: 'none', cursor: 'pointer'}}>
                    <Slider {...settings}>
                        {product.images.length == 0 ?
                            <div>
                                <img alt="fetch" src={img} className="imagehome" />
                            </div> :
                          product.images.map((product) => {
                          return(
                            <div>
                                <img alt="fetch" src={global.images + "ecommerce_photos/" + product.pei_image} className="imagehome" />
                            </div>
                          )
                        })
                      }
                    </Slider>
                  
                  <div style={{marginTop: 10, position: 'relative'}}>
                      <p className='product-name'>{product.name}</p>
                      <p style={{fontSize: 15, fontWeight: 'bold', marginBottom: 'unset'}}>{product.currency} {product.pb_onlineprice}</p>
                      {/* <p style={{fontSize: 12}}><span style={{textDecorationLine: 'line-through', color: '#5c5c5c'}}>{product.currency} {product.pb_onlineprice}</span></p> */}
                      {/* <div style={{marginTop: 25}}>
                        {logtoken ?
                          <Button onClick={() => AddCart(product.batchid)} variant="warning home-cartbtn" className="btn-warning">Add To Cart</Button>
                          :<Button onClick={() => toClick(product)} variant="warning home-cartbtn" className="btn-warning">Add To Cart</Button>}
                      </div> */}
                  </div>
                  </a>
            </div>
            {cartView ?
            <div  className="cart-content">
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        <p style={{fontWeight: 'bold', fontSize: 16, marginTop: 1}}>Cart</p>
                    </div>
                    <a onClick={closeviewCart}>
                        <AiOutlineClose color="#000" size={18} />
                    </a>
                </div>
                {logtoken ? 
                    <div className='innerdiv'>
                        {cartList.length == 0 ? <p style={{fontSize: 14, fontWeight: 'bold', textAlign: 'center', marginTop: 30}}>No Cart Added</p>:
                        <div>
                        <div>
                        {cartList.map((item, index) => (
                          <div>
                            <Row>
                                <Col xs={3}>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                            {
                                                item.image == null ?
                                                <img alt="fetch" src={img} style={{width: 80, height: 80}} /> :
                                                <img alt="fetch" src={global.images + "ecommerce_photos/" + item.image} style={{width: 80, height: 80}} />
                                            }
                                    </div>
                                </Col>
                                <Col xs={9}>
                                    <div>
                                        <p className='product-name' style={{fontSize: 11}}>{item.name}</p>
                                        <p style={{fontSize: 11, color: '#000', marginBottom: 'unset', fontWeight: 'bold'}}>{item.quantity} x {item.price} {item.currency}</p>
                                    </div>
                                </Col>
                            </Row>
                            <div style={{backgroundColor: '#d3d3d3', width: '100%', height: 1}}></div>
                          </div>
                        ))}
                        </div>
                        </div>
                        }
                    </div>:
                    <div className='innerdiv'>
                        {cart.length == 0 ? <p style={{fontSize: 14, fontWeight: 'bold', textAlign: 'center', marginTop: 30}}>No Cart Added</p>:
                        <div>
                        <div>
                        {cart.map((item, index) => (
                          <div>
                            <Row>
                                <Col xs={3}>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                            {
                                                item.id.images.length == 0 ?
                                                <img alt="fetch" src={img} style={{width: 80, height: 80}} /> :
                                                <img alt="fetch" src={global.images + "ecommerce_photos/" + item.id.images[0].pei_image} style={{width: 80, height: 80}} />
                                            }
                                    </div>
                                </Col>
                                <Col xs={9}>
                                    <div>
                                        <p className='product-name' style={{fontSize: 11}}>{item.id.name}</p>
                                        <p style={{fontSize: 11, color: '#000', marginBottom: 'unset', fontWeight: 'bold'}}>{item.qty} x {item.id.pb_onlineprice} {item.id.currency}</p>
                                    </div>
                                </Col>
                            </Row>
                            <div style={{backgroundColor: '#d3d3d3', width: '100%', height: 1}}></div>
                          </div>
                        ))}
                        </div>
                        
                        </div>
                        }
                    </div>
            }
            {logtoken ?
              <div style={{marginTop: 20}}>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <p style={{fontWeight: 'bold', fontSize: 14}}>Sub total</p>
                      <p style={{fontWeight: 'bold', fontSize: 14}}>QAR {cartTotal}</p>
                  </div>
                  <Row>
                    <Col xs={12} md={6}>
                    <Button onClick={() => history.push('/checkout')} variant="primary" style={{fontSize: 12, fontWeight: 'bold', width: '100%', borderColor: '#286bc7', backgroundColor: '#286bc7', color: '#fff', marginTop: 10, boxShadow: 'none'}}>Checkout</Button>
                    </Col>
                    <Col xs={12} md={6}>
                    <Button onClick={() => contHome()} variant="light" style={{fontSize: 12, fontWeight: 'bold', width: '100%', borderColor: '#286bc7', backgroundColor: 'none', color: '#286bc7', marginTop: 10, boxShadow: 'none'}}>Continue Shopping</Button>
                    </Col>
                  </Row>
              </div>:
              <div style={{marginTop: 20}}>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <p style={{fontWeight: 'bold', fontSize: 14}}>Sub total</p>
                      <p style={{fontWeight: 'bold', fontSize: 14}}>QAR {total}</p>
                  </div>
                  <Row>
                    <Col xs={6}>
                    <Button onClick={() => history.push('/login')} variant="primary" style={{fontSize: 12, fontWeight: 'bold', width: '100%', borderColor: '#286bc7', backgroundColor: '#286bc7', color: '#fff', marginTop: 10, boxShadow: 'none'}}>Checkout</Button>
                    </Col>
                    <Col xs={6}>
                    <Button onClick={() => contHome()} variant="light" style={{fontSize: 12, fontWeight: 'bold', width: '100%', borderColor: '#286bc7', backgroundColor: 'none', color: '#286bc7', marginTop: 10, boxShadow: 'none'}}>Continue Shopping</Button>
                    </Col>
                  </Row>
              </div>
            }
            </div>: null }
            <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal:'center' }}
             open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                 {message}
                </Alert>
            </Snackbar>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
      addToCart: (item) => dispatch(addToCart(item)),
      refresh: (item) => dispatch(refresh(item))
    };
  };

const mapStateToProps = (state) => {
  return {
      cart: state.shop.cart,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);