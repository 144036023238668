import * as actionTypes from "./shopping-types";
import {
  FETCH_PRODUCTS_BEGIN,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE
} from './shopping-actions';

const INITIAL_STATE = {
  products: [],
  cart: [],
  currentItem: null,
  refreshitem: false
};

const shopReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ADD_TO_CART:
      // Great Item data from products array
      // const item = state.products.find(
      //   (product) =>  action.payload
      // );
      console.log("action.payload", action.payload)
      const item = action.payload
      
      // Check if Item is in cart already
      var inCart = state.cart.some(item => item.id.batchid === action.payload.id.batchid)

      console.log("inCart", inCart)

      console.log("cart", state.cart)

      return {
        ...state,
        cart: inCart
          ? state.cart.map((item) =>
              item.id.batchid === action.payload.id.batchid
                ? { ...item, qty: item.qty + 1 }
                : item
            )
          : [...state.cart, { ...item, qty: 1 }],
      };

    case actionTypes.REMOVE_FROM_CART:
      return {
        ...state,
        cart: state.cart.filter((item) => item.id.batchid !== action.payload.id.batchid),
      };

    case actionTypes.REFRESH:
      console.log("refresh")
      return {
        ...state,
        refreshitem: state.refreshitem == false ? true : false,
      };

    case actionTypes.ADJUST_ITEM_QTY:
      // console.log("item.id.batchid")
      console.log("action.payload.qty",  action.payload.qty)
      return {
        ...state,
        cart: state.cart.map((item) =>
          item.id.batchid === action.payload.id
            ? { ...item, qty: +action.payload.qty }
            : item
        ),
      };

    case actionTypes.LOAD_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.payload,
      };

    case FETCH_PRODUCTS_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      console.log("ok")
      return {
        ...state,
        products: action.payload.products
      };


    default:
      return state;
  }
};

export default shopReducer;
