import React, {useState, useEffect} from 'react';
import './header.css';
import {Container, Row, Col, Form} from "react-bootstrap";
import { FaMobileAlt, FaListAlt } from "react-icons/fa";
import {FiSearch, FiLogIn} from "react-icons/fi"
import { MdDeliveryDining } from "react-icons/md";
import { RiCustomerService2Fill } from "react-icons/ri";
import { HiMenuAlt1 } from "react-icons/hi";
import { AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import logo from '../../Assets/Images/FetchLogo.svg';
import cartimage from '../../Assets/Images/cart.svg';
import {useHistory} from 'react-router-dom';
import profile from '../../Assets/Images/profile.svg';

import { connect } from "react-redux";

function Header({cart, refreshitem}) {
    let history = useHistory();
    const [cartCount, setCartCount] = useState(0);
    const [total, setTotal] = useState(0);
    const [logtoken, setLogtoken] = useState('');
    const [cartQty, setCartQty] = useState(0);
    const [cartTotal, setCartTotal] = useState(0);
    

  useEffect(() => {
    let count = 0;
    var total = 0;
    cart.forEach((item) => {
      count += item.qty;
      total += item.qty * item.id.pb_onlineprice;
      console.log("cart", cart)
    });

    setCartCount(count);
    setTotal(total);
  }, [cart, cartCount.toExponential]);

  useEffect(() => {  
    var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"))
    setLogtoken(logged)
    if(logged == true){
        getCartData()
    }
  },[refreshitem])

  const getCartData = () => {
    var token =  JSON.parse(localStorage.getItem("token"))
    fetch(global.url + "displayCartfrontend", {
        method: "POST",
         headers: {
             'Content-Type': 'application/json',
             'APPKEY': 'erp6129',
             'Authorization' : 'Bearer ' + token 
         },
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.error == false){
        console.log('cart data77', responseJson)
        setCartQty(responseJson.cart_count)
        setCartTotal(responseJson.total)
      }
      else{
        setCartQty(0)
        setCartTotal(0)
      }
    })
    .catch((error)=>{
        console.log(error)
    })
}

    return(
        <div>
          {logtoken ? 
            <a onClick={() => history.push('/cart')} style={{cursor: 'pointer'}}>
                <div className="responsive-cart1" style={{display: 'flex', marginLeft: 20}}>
                    <div>
                        <img alt="fetch" src={cartimage} className="carticon" />
                    </div>
                    <div className='searchbar' style={{marginLeft: 10, marginTop: 5}}>
                        <p style={{fontSize: 12, fontWeight: 500, marginBottom: 'unset', color: '#8f8f8f'}}>{cartQty} Products</p>
                        <p style={{fontSize: 14, fontWeight: 'bold', color: '#286bc7'}}>QAR {cartTotal}</p>
                    </div>
                </div>
            </a>:
            <a onClick={() => history.push('/cart')} style={{cursor: 'pointer'}}>
                <div className="responsive-cart1" style={{display: 'flex', marginLeft: 20}}>
                    <div>
                        <img alt="fetch" src={cartimage} className="carticon" />
                    </div>
                    <div className='searchbar' style={{marginLeft: 10, marginTop: 5}}>
                        <p style={{fontSize: 12, fontWeight: 500, marginBottom: 'unset', color: '#8f8f8f'}}>{cartCount} Products</p>
                        <p style={{fontSize: 14, fontWeight: 'bold', color: '#286bc7'}}>QAR {total}</p>
                    </div>
                </div>
            </a>}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
      cart: state.shop.cart,
      refreshitem: state.shop.refreshitem
    };
  };
  

export default connect(mapStateToProps)(Header);