import React, { useState, useEffect } from "react";
import './Cart.css';
import {Container, Row, Col, Button, Form } from "react-bootstrap";
import {useHistory} from 'react-router-dom';
import { connect } from "react-redux";
import Skeleton from '@mui/material/Skeleton';
import {
    refresh
  } from "../../redux/Shopping/shopping-actions";

import Header from '../../Components/Header/header';
import Footer from '../../Components/Footer/Footer';
import Img from '../../Assets/Images/products/shoe1.png';
import { RiDeleteBin5Line } from "react-icons/ri";
import Cartitem from './Cartitem';
import img from '../../Assets/Images/noimages.png';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../../Components/Loader/Loader'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Cart({cart, refresh}) {
    let history = useHistory();
    const [cartCount, setCartCount] = useState(0);
    const [total, setTotal] = useState(0);
    const [logtoken, setLogtoken] = useState(false)
    const [cartList, setCartList] = useState([])
    const [open, setOpen] = React.useState(false);
    const [cartQty, setCartQty] = useState('');
    const [cartTotal, setCartTotal] = useState('');
    const [shippingCharge, setShippingcharge] = useState(10);
    const [message, setMessage] = useState("");

    const [loader, setLoader] = useState(false);

      useEffect(() => {
        let count = 0;
        let total = 0;
        cart.forEach((item) => {
          count += item.qty;
          total += item.qty * item.id.pb_onlineprice;
        });
    
        setCartCount(count);
        setTotal(total);
        
      }, [cart, cartCount.toExponential]);

      useEffect(() => {
        
        var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"))
        setLogtoken(logged)
        console.log('loader', loader)
      },[cartList, loader])

      useEffect(() => {
        getCartData()
      },[cartList])


    const increm = (id) => {
        var token = JSON.parse(localStorage.getItem("token"))
        var data = {
            "operation": "plus",
            "cartid": id
        }
         fetch(global.url + "displayCartfrontend", {
             method: "POST",
             headers: {
                 'Content-Type': 'application/json',
                 'APPKEY': 'erp6129',
                 'Authorization' : 'Bearer ' + token 
             },
             body: JSON.stringify(data)
         })
         .then((response)=> response.json())
         .then((responseJson)=>{
             if(responseJson.error == false){
                setCartList(responseJson.data);
                refresh("")
             }
             else{
                 setOpen(true)
                 setMessage(responseJson.message)
             }
             
         })
         .catch((error)=>{
             console.log(error)
         })
    }

    const decrem = (id) => {
        var token = JSON.parse(localStorage.getItem("token"))
        var data = {
            "operation": "minus",
            "cartid": id
        }
         fetch(global.url + "displayCartfrontend", {
             method: "POST",
             headers: {
                 'Content-Type': 'application/json',
                 'APPKEY': 'erp6129',
                 'Authorization' : 'Bearer ' + token 
             },
             body: JSON.stringify(data)
         })
         .then((response)=> response.json())
         .then((responseJson)=>{
            
             if(responseJson.error == false){
                setCartList(responseJson.data);
                refresh("")
             }
             else{
                 setOpen(true)
                 setMessage(responseJson.message)
             }
             
         })
         .catch((error)=>{
             console.log(error)
         })
    }

    const getCartData = () => {
        // setLoader(true)
        var token =JSON.parse(localStorage.getItem("token"))
        fetch(global.url + "displayCartfrontend", {
            method: "POST",
             headers: {
                 'Content-Type': 'application/json',
                 'APPKEY': 'erp6129',
                 'Authorization' : 'Bearer ' + token 
             },
        })
        .then((response) => response.json())
        .then((responseJson) => {
            // setLoader(false)
          if(responseJson.error == false){
            setCartList(responseJson.data);
            setCartQty(responseJson.cart_count)
            setCartTotal(responseJson.total)
          }
          else{
            setCartList([]);
            setCartQty(0)
            setCartTotal(0)
          }
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    const removeCart = (id) => {
        var token = JSON.parse(localStorage.getItem("token"))
        var data = {
            cartid : id
        }
        
        fetch(global.url + "deleteCartfrontend", {
            method: "POST",
            headers: {
                'Content-Type' : 'application/json',
                'APPKEY' : 'erp6129',
                'Authorization' : 'Bearer ' + token 
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
          if(responseJson.error == false){
            setOpen(true);
            getCartData()
            setMessage(responseJson.message)
            refresh("")
          }
          else{
            refresh("")
            setOpen(true);
            getCartData()
            setMessage(responseJson.message)
          }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    return(
        <div>
            <div className="cartTop" >
                <Container>
                    {/* cart from server */}
                    {logtoken ? 
                        <div>
                            {loader ?
                            //  <Row style={{marginTop: 20}}>
                            //     <Col  xs={12} md={7} lg={8}>
                            //         <Skeleton sx={{ height: 150 }} animation="wave" variant="rectangular" />
                            //         <Skeleton sx={{ height: 150, marginTop: 2 }} animation="wave" variant="rectangular" />
                            //     </Col>
                            //     <Col  xs={12} md={5} lg={4}>
                            //         <Skeleton sx={{ height: 320 }} animation="wave" variant="rectangular" />
                            //     </Col>
                            // </Row>
                            <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
                                <div style={{marginTop: 50}}>
                                    <Loader />
                                </div>
                            </div>
                            :
                            <Row>
                                <Col xs={12} md={7} lg={8}>
                                    <div className='cart-div2'>
                                        <div style={{padding: 20}}>
                                            <p style={{fontWeight: 'bold', fontSize: 18, marginBottom: 'unset'}}>{cartQty} Item(s) in your bag</p>
                                        </div>
                                        {cartQty == 0 ? 
                                            <p style={{textAlign: 'center', fontSize: 20}}>Your Cart Is Empty</p>:    
                                        <div>
                                        {
                                            // loader ?
                                            // <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" /> :
                                            
                                            cartList.map((item) => (
                                            <div>
                                                <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                                                <div style={{padding: 20}}>
                                                    <Row>
                                                        <Col xs={12} md={6}>
                                                            <div>
                                                                <Row>
                                                                    <Col xs={12} lg={4}>
                                                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                                                            {
                                                                                item.image == null ?
                                                                                <img alt="fetch" src={img} style={{width: 120, height: 120}} /> :  
                                                                                <img alt="fetch" src={global.images + "ecommerce_photos/" + item.image} style={{width: 120, height: 120}} />
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={12} lg={8}>
                                                                        <div className='cart-prodiv'>
                                                                            <p style={{fontSize: 15, marginBottom: 'unset', marginTop: 15}}>{item.name}</p>
                                                                            <p style={{fontSize: 13, color: '#a9a9a9'}}>{item.color}, {item.size}</p>
                                                                        </div>  
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} md={6}>
                                                            <div>
                                                                <Row>
                                                                    <Col xs={12} md={6}>
                                                                        <div className='cart-qty'>
                                                                            <div style={{display: 'flex'}}>
                                                                                <div onClick={() => decrem(item.cart_id)} className='cart-decr'>-</div>
                                                                                <p style={{marginBottom: 0, marginLeft:10, marginRight: 10, fontSize: 14, marginTop: 2}}>{item.quantity}</p>
                                                                                <div  onClick={() => increm(item.cart_id)} className='cart-decr'>+</div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={12} md={6}>
                                                                        <p style={{color: '#FF5722', fontSize: 18, fontWeight: 'bold', textAlign: 'center',  marginTop: 20}}>QAR {item.price}</p>
                                                                        <a onClick={() =>removeCart(item.cart_id)}>
                                                                            <p style={{fontSize: 14, textAlign: 'center', cursor: 'pointer'}}><RiDeleteBin5Line  color="#000" size={17} style={{marginTop: -4}} /> Remove</p>
                                                                        </a>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        ))} 
                                        </div>
                                            }
                                    </div>
                                </Col>
                                <Col xs={12} md={5} lg={4}>
                                    
                                    <div className='cart-div2'>
                                        <div style={{padding: 20}}>
                                            <p style={{fontWeight: 'bold', fontSize: 18, marginBottom: 'unset'}}>Order Summary</p>
                                        </div>
                                        <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                                        <div style={{padding: 20}}>
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <p className="cart-checkoutpara">Subtotal ( {cartQty} Items )</p>
                                                <p className="cart-checkoutpara">QAR {cartTotal}</p>
                                            </div>
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <p className="cart-checkoutpara">Total shipping Charge</p>
                                                {cartTotal < 200 ?
                                                    <p className="cart-checkoutpara">QAR {shippingCharge}</p>:
                                                    <p className="cart-checkoutpara" style={{color: 'green'}}>Free</p>
                                                }
                                                
                                                {/* <p style={{fontSize: 13, color: 'green', marginTop: 4, fontWeight: 'bold'}}>QAR {shippingCharge}</p> */}
                                            </div>
                                            <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                                            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 13}}>
                                                <p className="cart-checkoutpara">Amount Payable</p>
                                                {cartTotal < 200 ?
                                                    <p className="cart-checkoutpara">QAR {cartTotal + shippingCharge}</p>:
                                                    <p className="cart-checkoutpara">QAR {cartTotal}</p>
                                                }
                                            </div>
                                            <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                                            {logtoken ? 
                                                <button onClick={() => history.push('/checkout')} type="button" class="btn btn-primary" style={{backgroundColor: '#286bc7', width: '100%'}}>Checkout</button>:
                                                <button onClick={() => history.push('/login')} type="button" class="btn btn-primary" style={{backgroundColor: '#286bc7', width: '100%'}}>Checkout</button>
                                            }
                                            <a onClick={() => history.push('/')} style={{cursor: 'pointer'}}>
                                                <p style={{color: '#286bc7', fontSize: 14, fontWeight: 'bold', marginTop: 20, textAlign: 'center'}}>Continue Shopping</p>
                                            </a>
                                        </div>
                                    </div>
                                    
                                </Col>
                            </Row>
                            }
                        </div>
                        :<Row>
                            {/* cart from redux */}
                            <Col xs={12} md={7} lg={8}>
                                <div className='cart-div2'>
                                    <div style={{padding: 20}}>
                                        <p style={{fontWeight: 'bold', fontSize: 18, marginBottom: 'unset'}}>{cartCount} Item(s) in your bag</p>
                                    </div>
                                    {cartCount == 0 ? 
                                            <p style={{textAlign: 'center', fontSize: 20}}>Your Cart Is Empty</p>:
                                    <div>
                                        {cart.map((item) => (
                                            <Cartitem key={item.id} item={item}/>
                                        ))} 
                                    </div>}
                                </div>
                            </Col>
                            <Col xs={12} md={5} lg={4}>
                                
                                <div className='cart-div2'>
                                    <div style={{padding: 20}}>
                                        <p style={{fontWeight: 'bold', fontSize: 18, marginBottom: 'unset'}}>Order Summary</p>
                                    </div>
                                    <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                                    <div style={{padding: 20}}>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <p className="cart-checkoutpara">Subtotal ( {cartCount} Items )</p>
                                            <p className="cart-checkoutpara">QAR {total}</p>
                                        </div>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <p className="cart-checkoutpara">Total shipping Charge</p>
                                            {total < 200 ?
                                                <p className="cart-checkoutpara">QAR {shippingCharge}</p>:
                                                <p className="cart-checkoutpara" style={{color: 'green'}}>Free</p>
                                            }
                                        </div>
                                        <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 13}}>
                                            <p className="cart-checkoutpara">Amount Payable</p>
                                            {total < 200 ?
                                            <p className="cart-checkoutpara">QAR {total + shippingCharge}</p>:
                                            <p className="cart-checkoutpara">QAR {total}</p>}
                                        </div>
                                        <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                                        {logtoken ? 
                                            <button onClick={() => history.push('/login')} type="button" class="btn btn-primary" style={{backgroundColor: '#286bc7', width: '100%'}}>Checkout</button>:
                                            <button onClick={() => history.push('/login')} type="button" class="btn btn-primary" style={{backgroundColor: '#286bc7', width: '100%'}}>Checkout</button>
                                        }
                                        <a onClick={() => history.push('/')} style={{cursor: 'pointer'}}>
                                            <p style={{color: '#286bc7', fontSize: 14, fontWeight: 'bold', marginTop: 20, textAlign: 'center'}}>Continue Shopping</p>
                                        </a>
                                    </div>
                                </div>
                                
                            </Col>
                        </Row>
                    }
                </Container>
                <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal:'center' }}
                open={open} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {message}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        cart: state.shop.cart,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      refresh: (item) => dispatch(refresh(item))
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(Cart);