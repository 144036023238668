import './ProductDetails.css';
import  React, {useState, useEffect} from 'react';
import {Container, Row, Col, Button, Form } from "react-bootstrap";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Related from '../../Components/RelatedItems/RelatedItems';
import Skeleton from '@mui/material/Skeleton';
import ImageGallery from 'react-image-gallery';
import { useHistory, useParams } from "react-router-dom";
import { BsPlus } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineMinusSm } from "react-icons/hi";
import {
    addToCart, adjustItemQty, refresh
  } from "../../redux/Shopping/shopping-actions";
  import MuiAlert from '@mui/material/Alert';
  import { connect } from "react-redux";
  import Snackbar from '@mui/material/Snackbar';
  import { FiCheck } from "react-icons/fi";
  import img from '../../Assets/Images/noimages.png';
  import Loader from '../../Components/Loader/Loader';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ProductDetails({addToCart, cart, item, adjustQty, refresh}){
    let history = useHistory();
    const {id, batch} = useParams();
    const [open, setOpen] = React.useState(false);
    const [cartList, setCartList] = useState([])
    const [value, setValue] = React.useState('1');
    const [relatedLists, setRelatedlists] = useState([])
    const [sliderloading, setSliderloading] = useState(false);
    const [cartCount, setCartCount] = useState(0);
    const [brand, setBrand] = useState('');
    const [productName, setProductName] = useState('');
    const [modalCode, setModalCode] = useState('');
    const [sellingPrice, setSellingPrice] = useState('');
    const [actuallPrice, setActualPrice] = useState('');
    const [imageList, setImagelist] = useState([])
    const [img1, setImg1] = useState([]);
    const [productId, setProductId] = useState('');
    const [sizeLists, setSizelist] = useState([]);
    const [color, setColor] = useState();
    const [getColor, setGetcolor] = useState('');
    const [prodctId1, setProductId1] = useState('');
    const [batch1, setBatch1] = useState();
    const [loader, setLoader] = useState(false);
    const [batchRelate, setBatchRelate] = useState('');
    const [productColor, setProductColor] = useState('');
    const [input, setInput] = useState(1);
    const [stock, setStock] = useState('0');
    const [currency, setCurrency] = useState('');
    const [data1, setData1] = useState({});
    const [batchids, setBatchid] = useState();
    const [logtoken, setLogtoken] = useState('');
    const [cartView, setCartview] = React.useState(false)
    const [message, setMessage] = useState('');
    const [cartTotal, setCartTotal] = useState('');
    const [details, setDetails] = useState({});
    const [cartQty, setCartQty] = useState('');
    const [colorlist, setColorlist] = useState([]);
    const [total, setTotal] = useState(0);
    const [urllink, setUrllink] = useState("");
    

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {  
        var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"))
        setLogtoken(logged)
        getCartData()
      },[])

      useEffect(() => {
        console.log("{window.location.href}", window.location.href)
        setUrllink(window.location.href)
      }, [])

    useEffect(() => {
    //    console.log("decodeURIComponent", atob(id, batch).replace("productid","batchid"));
        console.log("change")
        getData();
        getRelatedProduct();
       
    },[batch1,prodctId1])

    useEffect(() => {
        setProductId1(id)
        setBatch1(batch)
    },[id, batch])
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

      const contHome = () => {
        history.push('/')
        setCartview(false)
    }
    const closeviewCart = () => {
      setCartview(false)
  }

  const viewCart = () => {
    setCartview(true)
}

    const getData = () => {
        setLoader(true)
        if(logtoken == true){
            var user =  JSON.parse(localStorage.getItem("userdata"))
            console.log('user', user)
            var data = {
                'product_id' : prodctId1,
                'batch_id' : batch1,
                'user_id' : user.user_id
            }
            console.log("getData", data)
            fetch(global.url + "viewProductLatest", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'APPKEY': 'erp6129'
                },
                body: JSON.stringify(data)
            }).then((response) => response.json())
            .then((responseJson) => {
            console.log('productdetails', responseJson)
                if(responseJson.error == false){
                    setLoader(false)
                    setDetails(responseJson.data.details)
                    setSizelist(responseJson.data.details.size)
                    setColorlist(responseJson.data.colors)
                    if(responseJson.data.details.image) {
                        setImagelist(responseJson.data.details.image)
                    }else{
                        setImagelist([])
                    }
                }

            })
            .catch((error)=>{
                console.log(error)
            })
        }else{
            var data = {
                'product_id' : prodctId1,
                'batch_id' : batch1,
            }
            console.log("getData", data)
            fetch(global.url + "viewProductLatest", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'APPKEY': 'erp6129'
                },
                body: JSON.stringify(data)
            }).then((response) => response.json())
            .then((responseJson) => {
            console.log('productdetails', responseJson)
                if(responseJson.error == false){
                    setLoader(false)
                    setDetails(responseJson.data.details)
                    setSizelist(responseJson.data.details.size)
                    setColorlist(responseJson.data.colors)
                    if(responseJson.data.details.image) {
                        setImagelist(responseJson.data.details.image)
                    }else{
                        setImagelist([])
                    }
                }

            })
            .catch((error)=>{
                console.log(error)
            })
        }
        
    }

    const getCartData = () => {
        var token = JSON.parse(localStorage.getItem("token"))
        fetch(global.url + "displayCartfrontend", {
            method: "POST",
             headers: {
                 'Content-Type': 'application/json',
                 'APPKEY': 'erp6129',
                 'Authorization' : 'Bearer ' + token 
             },
        })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("responseJson cart123", responseJson)
          if(responseJson.error == false){
            setCartList(responseJson.data);
            setCartQty(responseJson.cart_count)
            setCartTotal(responseJson.total)
          } else {
            setCartList([])
            setCartQty(0)
            setCartTotal(0)
          }
        })
        .catch((error)=>{
            console.log(error)
        })
    }


    const getRelatedProduct = () => {
        setSliderloading(true)
        var data = {
            "product_id" : prodctId1,
            "batch_id" : batch1,
            "limit" : 12
        }
        fetch(global.url + "viewRelatedProducts", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data)
        }).then((response) => response.json())
        .then((responseJson) => {
            console.log('related', responseJson )
            if(responseJson.error == false){
                setSliderloading(false)
                setRelatedlists(responseJson.data)
            }
            else{
                setSliderloading(false)
                setRelatedlists([])
            }
        })
    }

    const colorChange = (event) => {
        setBatch1(event.target.value);
        var data = {
            'batch_id' : parseInt(event.target.value)
        }
        fetch(global.url + "viewProductbySize", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data)
        }).then((response) => response.json())
        .then((responseJson) => {
            
            if(responseJson.error == false){
                var data = responseJson.data[0];
                var batch = data.batchid
                setBatchid(batch)
                setData1(data[0])
                setBatch1(data.pb_id)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    };

    const handleIncre = () => {
        if(input >= details.pb_stock){
            console.log("ifinput", input)
        }
        else{
            setInput(prevCount => prevCount + 1)
        }       
    }

    const handleDecre = () => {
        if(input > 1) {
            setInput(prevCount => prevCount - 1)
        }
    }

    const toClick = (data1) => {
        addToCart(data1)
        adjustQty(batchids, input);
        setMessage("Added to Cart")
        setOpen(true)
        viewCart()
      }

      const AddCart = () => {
        var token = JSON.parse(localStorage.getItem("token"))
        var data = {
            "productid" : batch1,
            "quantity" : input
        }
        fetch(global.url + "addCartfrontend", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
              'APPKEY': 'erp6129',
              'Authorization' : 'Bearer ' + token 
          },
          body: JSON.stringify(data)
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          if(responseJson.error == false){
            setOpen(true)
            setMessage("Added To Cart")
            refresh("")
            viewCart()
            getCartData()
          } else {
            refresh("")
            setOpen(true)
            setMessage(responseJson.message)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
      }

      
      useEffect(() => {
        let count = 0;
        var total = 0;
        cart.forEach((item) => {
          count += item.qty;
          total += item.qty * item.id.pb_onlineprice;
        })
        setCartCount(count);
        setTotal(total);
       
      }, [cart, cartCount.toExponential]);

    const images = [
        {
          original: 'https://homestaymatch.com/images/no-image-available.png',
          thumbnail: 'https://homestaymatch.com/images/no-image-available.png',
        }, 
    ];

    const colorClick = (id) => {
        setBatch1(id)
    }

    const AddWishlist = () => {
        var token = JSON.parse(localStorage.getItem("token"))
        const data = {
            mode: 'add',
            batch_id: batch1,
        }
    
        fetch(global.url + 'AddtowishlistOrDelete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            window.location.reload()
        if(responseJson == false){
         
            setOpen(true)
            setMessage("Added To Cart")
        }
        else{
            setOpen(true)
            setMessage(responseJson.message)
        }
        })
    }

    return(
        <div>
            <Container>
                {loader ?
                    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
                        <div style={{marginTop: 150}}>
                            <Loader />
                        </div>
                    </div>:
                <div>
                    <div className='detailsMargintop'>
                    <Row>
                        <Col xs={12} md={5}>
                             {/* <div>
                               <Skeleton sx={{ height: 350, marginTop: 1, width: '100%' }} animation="wave" variant="rectangular" />
                                <Row>
                                    <Col xs={3}>
                                       <Skeleton sx={{ height: 100, marginTop: 2, width: '100%' }} animation="wave" variant="rectangular" />
                                   </Col>
                                  <Col xs={3}>
                                      <Skeleton sx={{ height: 100, marginTop: 2, width: '100%' }} animation="wave" variant="rectangular" />
                                  </Col>
                                    <Col xs={3}>
                                         <Skeleton sx={{ height: 100, marginTop: 2, width: '100%' }} animation="wave" variant="rectangular" />
                                   </Col>
                                <Col xs={3}>
                                   </Col>
                                 </Row>
                            </div> */}
                            
                            <div>
                                <div className="details-imgtext">
                                    <p style={{color: '#959595', fontSize: 15, marginBottom: 'unset'}}>{details.brand_name}</p>
                                    <h5>{details.product_name} ({details.color_name})</h5>
                                </div>
                                {imageList.length == 0 ?
                                <ImageGallery items={images} showPlayButton={false} />:
                                <ImageGallery items={imageList} showPlayButton={false} />}
                            </div>
                        </Col>
                        <Col xs={12} md={7}>
                            <div className='product-left'>
                                <div className="details-righttext">
                                    <div>
                                        <p style={{color: '#959595', fontSize: 15, marginBottom: 'unset'}}>{details.brand_name}</p>
                                        <h5>{details.product_name} ({details.color_name})</h5>
                                    </div>
                                </div>
                                    <p className="details-righttext1" style={{fontSize: 14}}>Product Code : {details.product_refcode}</p>
                                <div style={{display: 'flex'}}>
                                    <h5 className="details-price" style={{marginBottom: 'unset'}}>{details.currency} {details.pb_onlineprice}</h5>
                                    {/* <p style={{textDecorationLine: 'line-through', color: '#5c5c5c', fontSize: 14, marginTop: 3, marginLeft: 10, fontWeight: 'bold'}}>{currency} {actuallPrice}</p> */}
                                    {/* <div className="off-border">
                                        <p style={{marginBottom: 'unset', color: '#00ac66', fontSize: 12, fontWeight: 'bold'}}>73% OFF</p>
                                    </div> */}
                                </div>
                                <div style={{marginTop: 20}}>
                                    {/* <Row>
                                         <Col xs={12}>
                                                <Row>
                                                    <Col xs={3}>
                                                        <p style={{fontSize: 16, marginTop: 8}}>Size & Colour</p>
                                                    </Col>
                                                    <Col xs={8}>
                                                        {loader ?
                                                            <Skeleton sx={{ height: 30, marginTop: 1, width: '100%' }} animation="wave" variant="rectangular" /> :
                                                            <Form.Select 
                                                                aria-label="Default select example" 
                                                                style={{boxShadow: 'none'}}
                                                                value={batch1}
                                                                onChange={colorChange} >
                                                                <option value=''>Select Size & Colour</option>
                                                                {sizeLists.map((item, index) => (
                                                                    <option value={item.pb_id}>{item.color_name} - {item.size_name}</option>
                                                                ))}
                                                            </Form.Select>
                                                        }
                                                    </Col>                              
                                                </Row>
                                            </Col>
                                        </Row> */}
                                    <Row>
                                    <Col  xs={12}>
                                        <div style={{display: 'flex', marginTop: 20}}>
                                            {/* <p style={{marginBottom: 'unset', marginTop: 7, color: '#000'}}>Colors</p> */}
                                            {/* {
                                                colorlist.map((i, k) => {
                                                    return(
                                                    <div onClick={() => colorClick(i.pb_id)} className="size-border" style={{background: i.color_name, borderColor: '#0a003e'}}>
                                                        {
                                                            i.color_name ==  details.color_name ?
                                                            <FiCheck color="#fff" fontSize={20} style={{marginTop: -5}}/>:
                                                            null
                                                        }
                                                        
                                                    </div>
                                                    )
                                                })
                                            } */}


                                                {
                                                    loader ?
                                                    <div>
                                                        <Row>
                                                            <Skeleton sx={{ height: 60, width: 60, marginLeft: 2  }} animation="wave" variant="rectangular" />
                                                            <Skeleton sx={{ height: 60, width: 60, marginLeft: 2  }} animation="wave" variant="rectangular" />
                                                            <Skeleton sx={{ height: 60, width: 60, marginLeft: 2  }} animation="wave" variant="rectangular" />
                                                        </Row>
                                                    </div> :
                                                    colorlist.map((i, k) => {
                                                        return(
                                                            
                                                            i.images == 0 ? 
                                                            null :
                                                            <div onClick={() => colorClick(i.pb_id)} className={i.color_name ==  details.color_name ? 'activecolor' : 'notactive'} style={{cursor: 'pointer', marginRight :10}}>
                                                                <img alt="fetch" src={global.images + "ecommerce_photos/" + i.images[0].pei_image} style={{width: 70, height: 70}} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                                             
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="size" style={{display: 'flex', marginTop: 20}}>
                                            <p style={{marginBottom: 'unset', marginTop: 7, color: '#000', marginRight: 5}}>Size</p>
                                            {
                                                sizeLists.map((i, k) => {
                                                    return(
                                                        <div onClick={() => colorClick(i.pb_id)} className={i.pb_id == batch1 ?  "size-border active": "size-border"}>
                                                            <p className="sizeText">{i.size_name}</p>
                                                        </div>
                                                    )
                                                   
                                                })
                                            }
                                      </div>
                                    </Col>
                                </Row>
                                </div>
                                <div>
                                    <Row>
                                        <Col xs={12} md={8} lg={4} style={{marginTop: 20}}>
                                            <Row>
                                                <Col xs={4} sm={2} md={4}>
                                                    <p style={{fontSize: 16, marginTop: 8}}>Qty</p>
                                                </Col>
                                                <Col xs={6} sm={5} md={8}>
                                                    {loader ?  <Skeleton sx={{ height: 30, marginTop: 1, width: '100%' }} animation="wave" variant="rectangular" /> :
                                                        <div>
                                                            {details.pb_stock == 0 ? 
                                                                <p style={{fontSize: 12, color: 'red', marginTop: 13}}>Out of Stock</p>    
                                                            :
                                                                // <Form.Select aria-label="Default select example" style={{boxShadow: 'none'}}>
                                                                //     {options}
                                                                // </Form.Select>
                                                                <div style={{marginTop: 12}}>
                                                                    <div style={{display: 'flex'}}>
                                                                        <div onClick={handleDecre} className='cart-decr'><HiOutlineMinusSm color="#000" size={15}/></div>
                                                                        <p style={{marginBottom: 0, marginLeft:10, marginRight: 10, fontSize: 14, marginTop: 2}}>{input}</p>
                                                                        <div onClick={handleIncre} className='cart-decr'><BsPlus color="#000" size={15}/></div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </Col>                              
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} lg={3} style={{marginTop: 30}}>
                                            {logtoken ? 
                                                <Button onClick={() => AddCart()} variant="primary" style={{boxShadow: 'none', width: '100%'}} className="btn-warning home-cartbtn buttonStyledel">Add To Cart</Button>:
                                                <Button onClick={() => toClick(details)} variant="primary" style={{boxShadow: 'none', width: '100%'}} className="btn-warning home-cartbtn buttonStyledel">Add To Cart</Button>
                                            }
                                        </Col>
                                        <Col xs={12} md={12} lg={3} style={{marginTop: 20}}>
                                        {logtoken ? 
                                            <div>
                                            {details.wishlist == true ? null : 
                                            <Button onClick={AddWishlist} variant="primary" className='wishbtn'>Add To Wishlist</Button>}
                                            </div>:
                                            <Button onClick={() => history.push('/login')} variant="primary" className='wishbtn'>Add To Wishlist</Button>
                                        }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} lg={3} style={{marginTop: 30}}>
                                            <a style={{textDecoration: 'none'}} href={"https://wa.me/+97430003861/?text=" + urllink} target="_blank">
                                             <Button variant="primary" style={{boxShadow: 'none', width: '100%', border: 'none', backgroundColor: '#5ac345', color: '#fff', fontWeight: 600}} ><span style={{color: '#fff'}}>Order By Whatsapp</span></Button>
                                             </a>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>  
                    </Row>
                </div>
                <div style={{marginTop: 30}}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Overview" value="1" />
                                <Tab label="Specifications" value="2" />
                            </TabList>
                            </Box>
                            <TabPanel value="1">
                                <div>
                                    <div>
                                        <p style={{fontSize: 14}}>{productId}</p>
                                    </div> 
                                </div>
                            </TabPanel>
                            <TabPanel value="2">
                                <div>
                                </div> 
                            </TabPanel>
                        </TabContext>
                    </Box>
                </div>
                <div style={{marginTop: 30, marginBottom: 50}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <h2 style={{color: '#000', fontSize: 28, fontWeight: 'bold'}}>Related Products</h2>
                    </div>
                    <div>
                        {
                            relatedLists.length == 0 ? 
                            <p>No Related Products</p>:
                            <Related data={relatedLists} /> }
                            </div>
                            </div>
                </div>}

                {/* cart pop */}

                {cartView ?
                    <div  className="cart-content">
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>
                                <p style={{fontWeight: 'bold', fontSize: 16, marginTop: 1}}>Cart</p>
                            </div>
                            <a onClick={closeviewCart}>
                                <AiOutlineClose color="#000" size={18} />
                            </a>
                        </div>
                        {logtoken ? 
                            <div className='innerdiv'>
                                {cartList.length == 0 ? <p style={{fontSize: 14, fontWeight: 'bold', textAlign: 'center', marginTop: 30}}>No Cart Added</p>:
                                <div>
                                <div>
                                {cartList.map((item, index) => (
                                <div>
                                    <Row>
                                        <Col xs={3}>
                                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                                    {
                                                        item.image == null ?
                                                        <img alt="fetch" src={img} style={{width: 80, height: 80}} /> :
                                                        <img alt="fetch" src={global.images + "ecommerce_photos/" + item.image} style={{width: 80, height: 80}} />
                                                    }
                                            </div>
                                        </Col>
                                        <Col xs={9}>
                                            <div>
                                                <p className='product-name' style={{fontSize: 11}}>{item.name}</p>
                                                <p style={{fontSize: 11, color: '#000', marginBottom: 'unset', fontWeight: 'bold'}}>{item.quantity} x {item.price} {item.currency}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div style={{backgroundColor: '#d3d3d3', width: '100%', height: 1}}></div>
                                </div>
                                ))}
                                </div>
                                </div>
                                }
                            </div>:
                            <div className='innerdiv'>
                                {cart.length == 0 ? <p style={{fontSize: 14, fontWeight: 'bold', textAlign: 'center', marginTop: 30}}>No Cart Added</p>:
                                <div>
                                <div>
                                {cart.map((item, index) => (
                                <div>
                                    <Row>
                                        <Col xs={3}>
                                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                                    {
                                                        item.id.images.length == 0 ?
                                                        <img alt="fetch" src={img} style={{width: 80, height: 80}} /> :
                                                        <img alt="fetch" src={global.images + "ecommerce_photos/" + item.id.images[0].pei_image} style={{width: 80, height: 80}} />
                                                    }
                                            </div>
                                        </Col>
                                        <Col xs={9}>
                                            <div>
                                                <p className='product-name' style={{fontSize: 11}}>{item.id.name}</p>
                                                <p style={{fontSize: 11, color: '#000', marginBottom: 'unset', fontWeight: 'bold'}}>{item.qty} x {item.id.pb_onlineprice} {item.id.currency}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div style={{backgroundColor: '#d3d3d3', width: '100%', height: 1}}></div>
                                </div>
                                ))}
                                </div>
                                
                                </div>
                                }
                            </div>
                    }
                    {logtoken ?
                    <div style={{marginTop: 20}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <p style={{fontWeight: 'bold', fontSize: 14}}>Sub total</p>
                            <p style={{fontWeight: 'bold', fontSize: 14}}>QAR {cartTotal}</p>
                        </div>
                        <Row>
                            <Col xs={12} md={6}>
                            <Button onClick={() => history.push('/checkout')} variant="primary" style={{fontSize: 12, fontWeight: 'bold', width: '100%', borderColor: '#286bc7', backgroundColor: '#286bc7', color: '#fff', marginTop: 10, boxShadow: 'none'}}>Checkout</Button>
                            </Col>
                            <Col xs={12} md={6}>
                            <Button onClick={() => contHome()} variant="light" style={{fontSize: 12, fontWeight: 'bold', width: '100%', borderColor: '#286bc7', backgroundColor: 'none', color: '#286bc7', marginTop: 10, boxShadow: 'none'}}>Continue Shopping</Button>
                            </Col>
                        </Row>
                    </div>:
                    <div style={{marginTop: 20}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <p style={{fontWeight: 'bold', fontSize: 14}}>Sub total</p>
                            <p style={{fontWeight: 'bold', fontSize: 14}}>QAR {total}</p>
                        </div>
                        <Row>
                            <Col xs={6}>
                            <Button onClick={() => history.push('/login')} variant="primary" style={{fontSize: 12, fontWeight: 'bold', width: '100%', borderColor: '#286bc7', backgroundColor: '#286bc7', color: '#fff', marginTop: 10, boxShadow: 'none'}}>Checkout</Button>
                            </Col>
                            <Col xs={6}>
                            <Button onClick={() => contHome()} variant="light" style={{fontSize: 12, fontWeight: 'bold', width: '100%', borderColor: '#286bc7', backgroundColor: 'none', color: '#286bc7', marginTop: 10, boxShadow: 'none'}}>Continue Shopping</Button>
                            </Col>
                        </Row>
                    </div>
                    }
                    </div>: null }

                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal:'center' }}
                    open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {message}
                    </Alert>
                </Snackbar>
            </Container>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
      addToCart: (item) => {
          dispatch(addToCart(item))
        },
        adjustQty: (id, value) => dispatch(adjustItemQty(id, value)),
        refresh: (item) => dispatch(refresh(item))
    };
  };
  const mapStateToProps = (state) => {
    return {
        cart: state.shop.cart,
    };
  };

  export default connect( mapStateToProps, mapDispatchToProps)(ProductDetails);