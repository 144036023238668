import React, { useState, useEffect } from "react";
import './Checkout.css';
import {Container, Row, Col, Button } from "react-bootstrap";
import img from '../../Assets/Images/noimages.png';
import {useHistory} from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Skeleton from '@mui/material/Skeleton';
import Loader from '../../Components/Loader/Loader'

import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Checkout() {
    let history = useHistory();
    const [zone, setZone] = useState('');
    const [streat, setStreat] = useState('');
    const [remarks, setRemarks] = useState('');
    const [state, setState] = useState('');
    const [area, setArea] = useState('');
    const [buldingNo, setBildingNo] = useState('');
    const [mobile, setMobile] = useState('');
    const [cartList, setCartList] = useState([])
    const [open, setOpen] = React.useState(false);
    const [cartQty, setCartQty] = useState('');
    const [cartTotal, setCartTotal] = useState('');
    const [total, setTotal] = useState(0);
    const [shippingCharge, setShippingcharge] = useState(10);
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState("");
    const [namea, setNamea] = useState('');
    const [mobilea, setMobilea] = useState('');

    useEffect(() => {
        getAddress();
    })

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };


    useEffect(() => {
        getCartData()
      },[])

      const getCartData = () => {
            setLoader(true)
            var token = JSON.parse(localStorage.getItem("token"))
            fetch(global.url + "displayCartfrontend", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'APPKEY': 'erp6129',
                    'Authorization' : 'Bearer ' + token 
                },
            })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("cart item", responseJson)
                setLoader(false)
                if(responseJson.error == false){
                    setCartList(responseJson.data);
                    setCartQty(responseJson.cart_count)
                    setCartTotal(responseJson.total)
                    
                }
            })
            .catch((error)=>{
                console.log(error)
            })
      }

    const getAddress = () => {
        var token = JSON.parse(localStorage.getItem("token"))
        var data = {
            "showData" : true
        }
        fetch(global.url + "EditfetchUserData", {
            method: "POST",
            headers: {
                'Content-Type' : 'application/json',
                'APPKEY' : 'erp6129',
                'Authorization' : 'Bearer ' + token 
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log("address response", responseJson)
            if(responseJson.error == false){
                var data1 = responseJson.data
                var data = data1.user_address
                setBildingNo(data.buildingno)
                setZone(data.zone)
                setRemarks(data.remarks)
                setStreat(data.street)
                setNamea(data.name)
                setMobilea(data.mobile)
                setArea(data.area)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const onProceed = () => {
        if(buldingNo == null || streat == null || remarks == null || zone == null || area == null || namea == null || mobilea == null){
            setOpen(true)
            setMessage("Please Fill All Address Details")
        }
        else{
            var token = JSON.parse(localStorage.getItem("token"))
            var data = {
                "delivery_charge" : shippingCharge,
                "salesorder_subtotal": cartTotal,
                "salesorder_total": cartTotal + shippingCharge
            }
            console.log('data', data)
            fetch(global.url + "finalCheckoutfrontend", {
                method: "POST",
                headers: {
                    'Content-Type' : 'application/json',
                    'APPKEY' : 'erp6129',
                    'Authorization' : 'Bearer ' + token 
                },
                body: JSON.stringify(data)
            })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("finalCheckout responseJson", responseJson)
                if(responseJson.error == false){
                    setMessage(responseJson.message)
                    setOpen(true)
                    history.push('/success')
                    window.location.reload()
                }
                else{
                    setOpen(true)
                    setMessage(responseJson.message)
                }

            })
            .catch((error)=>{
                console.log(error)
            })
        }
    }
  return (
    <div className="cartTop">
        <div className="checkout-maindiv">
            <Container>
                {loader ? 
                    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
                        <div style={{marginTop: 50}}>
                            <Loader />
                        </div>
                    </div>
                    :
                <Row>
                    <Col xs={12} md={7} lg={8}>
                        <div className='checkout-div' style={{padding: 20}}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <p style={{fontSize: 16, fontWeight: 'bold'}}>Delivery Address</p>
                                <a>
                                    <p onClick={() => history.push('/profile')} style={{fontSize: 14, fontWeight: 'bold', color: '#286bc7',cursor: 'pointer'}}>Change</p>
                                </a>
                            </div>
                            <div>
                                {
                                    loader ? 
                                    <Skeleton variant="text" />: 
                                    <div>
                                        <p style={{fontSize: 14}}>Name: {namea}</p>
                                        <p style={{fontSize: 14}}>Mobile: {mobilea}</p>
                                        <p style={{fontSize: 14}}>Building No.: {buldingNo}</p>
                                        <p style={{fontSize: 14}}>Street : {streat}</p>
                                        <p style={{fontSize: 14}}>Zone: {zone}</p>
                                        <p style={{fontSize: 14}}>Area: {area}</p>
                                        <p style={{fontSize: 14}}>Remarks: {remarks}</p>
                                    </div>
                                }
                               
                                
                            </div>
                        </div>
                        <div className='checkout-div' style={{padding: 20}}>
                            {
                                cartList.map((item, k) => {
                                    return(
                                        <Row style={{marginTop: 10}}>
                                            <Col xs={12} lg={4}>
                                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                                        {
                                                            item.image == null ?
                                                            <img alt="fetch" src={img} style={{width: 120, height: 120}} /> : 
                                                            <img alt="fetch" src={global.images + "ecommerce_photos/" + item.image} style={{width: 120, height: 120}} /> 
                                                        }
                                                    </div>
                                            </Col>
                                            <Col xs={12} lg={8}>
                                                <div className='checkout-prodiv'>
                                                    <p style={{fontSize: 15, marginBottom: 'unset', marginTop: 15}}>{item.name}</p>
                                                    <p style={{fontSize: 13, color: '#a9a9a9'}}>{item.color}, {item.size}</p>
                                                    <p style={{fontSize: 13, color: '#a9a9a9'}}>QTY - {item.quantity}</p>
                                                    <p style={{color: '#FF5722', fontSize: 18, fontWeight: 'bold', marginTop: 20}}>QAR {item.price}</p>
                                                </div>  
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                            
                        </div>
                    </Col>
                    <Col xs={12} md={5} lg={4}>
                        <div className='checkout-div'>
                            <div style={{padding: 20}}>
                                <p style={{fontWeight: 'bold', fontSize: 18, marginBottom: 'unset'}}>Order Summary</p>
                            </div>
                            <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                            <div style={{padding: 20}}>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <p className="check-checkoutpara">Subtotal ( {cartQty} Items )</p>
                                    <p className="check-checkoutpara">QAR {cartTotal}</p>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <p className="check-checkoutpara">Total shipping Charge</p>
                                    {cartTotal < 200 ?
                                    <p className="cart-checkoutpara">QAR {shippingCharge}</p>:
                                    <p className="cart-checkoutpara" style={{color: 'green'}}>Free</p>
                                    }
                                </div>
                                <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 13}}>
                                    <p className="check-checkoutpara">Amount Payable</p>
                                    {cartTotal < 200 ? 
                                        <p className="check-checkoutpara">QAR {cartTotal + shippingCharge}</p>:
                                        <p className="check-checkoutpara">QAR {cartTotal}</p>
                                    }
                                </div>
                                <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                                <button onClick={onProceed} type="button" class="btn btn-primary" style={{backgroundColor: '#286bc7', width: '100%'}}>Proceed</button>
                            </div>
                        </div>
                    </Col>
                </Row>
                }
            </Container>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal:'center' }}
                open={open} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {message}
                    </Alert>
            </Snackbar>
        </div>
    </div>
  )
}

export default Checkout;