import React, {useState, useEffect} from 'react';
import './Signup.css';
import {Container, Row, Col, Button, Form } from "react-bootstrap";
import logo from '../../Assets/Images/FetchLogo.svg';
import {useHistory} from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../../Components/Loader/Loader';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


function Signup(){
    let history = useHistory();
    const vertical = "top"
    const horizontal = "center"
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [location, setLocation] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');

    const [nameErr, setNameErr] = useState({});
    const [emailErr, setEmailErr] = useState({});
    const [mobileErr, setMobileErr] = useState({});
    const [locationErr, setLocationErr] = useState({});
    const [passwordErr, setPasswordErr] = useState({});
    const [confirmErr, setConfirmErr] = useState({});

    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrormessage] = React.useState(false);
    const [loader, setLoader] = React.useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        const isValid = formValidation();
    }

    const formValidation = (e) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const re = /^[0-9\b]+$/;
        const nameErr = {};
        const emailErr = {};
        const mobileErr = {};
        const locationErr = {};
        const passwordErr = {};
        const confirmErr = {};
        let isValid = true;

        if(name.trim().length == 0){
            nameErr.namerequired = 'Name is required'
        }
        if(email.trim().length == 0){
            emailErr.emailRequired = 'Email id is required';
            isValid = false;
        } 
        if (!regex.test(email)){
            emailErr.emailRequired = 'Email id is not valid';
            isValid = false;
        }
        if(mobile.trim().length == 0){
            mobileErr.mobileRequired = 'Mobile Number is required';
            isValid = false;
        }
        if (!re.test(mobile)){
            mobileErr.mobileRequired = 'Invalid Mobile Number';
            isValid = false;
        }
        if(location.trim().length == 0){
            locationErr.locationRequired = 'Mobile Number is required';
            isValid = false;
        }
        if(password.trim().length == 0){
            passwordErr.passwordRequired = 'Password is required';
            isValid = false;
        }
        if(password.trim().length < 6){
            passwordErr.passwordRequired = 'Password is too short (Min 6 Letters)';
            isValid = false;
        }
        if(password.trim().length > 20){
            passwordErr.passwordRequired = 'Password is too long (Max 20 Letters)';
            isValid = false;
        }
        if(confirm.trim().length == 0){
            confirmErr.passwordRequired = 'Confirm Password required';
            isValid = false;
        }
        if(name.trim().length != 0 && email.trim().length != 0 && mobile.trim().length != 0 && location.trim().length != 0 &&
        password.trim().length != 0 && regex.test(email) && re.test(mobile))  {
            getData()
        }
        setNameErr(nameErr);
        setEmailErr(emailErr);
        setMobileErr(mobileErr);
        setLocationErr(locationErr);
        setPasswordErr(passwordErr);
        setConfirmErr(confirmErr);
        return isValid  
    }
    const handleClose = (event, reason) => {
        if(reason === 'clickaway'){
            return;
        }
        setOpen(false);
    }

    const getData = () => {
        setLoader(true)
        var data = {
            "fullname" : name,
            "phone" : mobile,
            "email" : email,
            "location" : location,
            "password" : password,
            'confirm_password' : confirm
        }
        fetch(global.url + "auth/signUp", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            console.log("response signup", responseJson)  
            setLoader(false)
            if(responseJson.error == false){
                var data = responseJson.data
                var data1 = data.user_data
                console.log("data", data1)
                localStorage.setItem("isAuthenticatedfront", true);
                localStorage.setItem("token", JSON.stringify(data.api_token));
                localStorage.setItem("userName", JSON.stringify(data1.user_name));
                localStorage.setItem("userdata", JSON.stringify(data1));
                history.push('/');
            }else{
                setOpen(true)
                setErrormessage(responseJson.message)
            }   
          })
          .catch((error)=>{
              console.log(error)
          })
    }
    
    return(
        <div className="App">
            <Container fluid>
                <Row>
                    <Col xs={12} style={{marginTop: 20}}> 
                        <div className="signup-box">
                            <div className="box-inside">
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20}}>
                                    <img alt="fetch" src={logo} />
                                </div>
                                <p className='signuppara'>Register Account</p>
                                <div style={{marginTop: 30}}>
                                    <Form>
                                        <Form.Group className="mb-1" controlId="formBasicEmail">
                                            <Form.Label>Fullname</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                className='shadow12' 
                                                value={name}
                                                onChange={(e) => {setName(e.target.value)}} 
                                                placeholder='Enter Your Fullname' />
                                        </Form.Group>
                                        {Object.keys(nameErr).map((key) => {
                                            return <div style={{color: 'red', fontSize: 12}}>{nameErr[key]}</div>
                                        })}
                                        <Form.Group className="mb-1 mt-2" controlId="formBasicEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control 
                                                type="email"
                                                className='shadow12' 
                                                value={email}
                                                onChange={(e) => {setEmail(e.target.value)}} 
                                                placeholder='Enter Your Email Address' />
                                        </Form.Group>
                                        {Object.keys(emailErr).map((key) => {
                                            return <div style={{color: 'red', fontSize: 12}}>{emailErr[key]}</div>
                                        })}
                                        <Form.Group className="mb-1 mt-2" controlId="formBasicEmail">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Row>
                                                <Col xs={3}>
                                                    <Form.Control type="phone" value={974} placeholder='Code' />
                                                </Col>
                                                <Col xs={9}>
                                                    <Form.Control 
                                                        type="phone"
                                                        className='shadow12'
                                                        value={mobile}
                                                        onChange={(e) => {setMobile(e.target.value)}} 
                                                        placeholder='Enter Your Mobile Number' />
                                                        {Object.keys(mobileErr).map((key) => {
                                                            return <div style={{color: 'red', fontSize: 12}}>{mobileErr[key]}</div>
                                                        })}
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group className="mb-1 mt-2" controlId="formBasicEmail">
                                            <Form.Label>Location</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                className='shadow12' 
                                                value={location}
                                                onChange={(e) => {setLocation(e.target.value)}} 
                                                placeholder='Enter Location' />
                                        </Form.Group>
                                        {Object.keys(locationErr).map((key) => {
                                            return <div style={{color: 'red', fontSize: 12}}>{locationErr[key]}</div>
                                        })}
                                        <Form.Group className="mb-1 mt-2" controlId="formBasicEmail">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control 
                                                type="password"
                                                className='shadow12' 
                                                value={password}
                                                onChange={(e) => {setPassword(e.target.value)}} 
                                                placeholder='Enter Your Password' />
                                        </Form.Group>
                                        {Object.keys(passwordErr).map((key) => {
                                            return <div style={{color: 'red', fontSize: 12}}>{passwordErr[key]}</div>
                                        })}
                                        <Form.Group className="mb-1 mt-2" controlId="formBasicEmail">
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control 
                                                type="password"
                                                className='shadow12' 
                                                value={confirm}
                                                onChange={(e) => {setConfirm(e.target.value)}} 
                                                placeholder='Confirm Your Password' />
                                        </Form.Group>
                                        {Object.keys(confirmErr).map((key) => {
                                            return <div style={{color: 'red', fontSize: 12}}>{confirmErr[key]}</div>
                                        })}
                                        {/* <div>
                                            {['checkbox'].map((type) => (
                                                <div key={`inline-${type}`} className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        label="I agree to terms and privacy policy ."
                                                        name="group1"
                                                        type={type}
                                                        id={`inline-${type}-1`}
                                                        style={{fontSize: 13}}
                                                    />
                                                </div>
                                            ))}
                                        </div> */}
                                    </Form>
                                </div>
                                <div style={{marginTop: 30}}>
                                {loader ? 
                                    <Loader />:
                                    <Button onClick={onSubmit} variant="primary" style={{boxShadow: 'none', border: 'none', backgroundColor: '#286bc7', width: '100%'}}>Signup</Button>
                                }
                                    </div>
                                <div style={{marginTop: 20}}>
                                    <p style={{textAlign: 'center', fontSize: 14, fontWeight: 500}}>Have an account? <a onClick={() => history.push('/login')}><span style={{color: '#286bc7', cursor: 'pointer'}}>Login</span></a></p>
                                    <a onClick={() => history.push('/')}><p style={{textAlign: 'center', fontSize: 14, fontWeight: 500, color: '#286bc7', cursor: 'pointer'}}>Back to Home</p></a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Snackbar anchorOrigin={{vertical, horizontal}} open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </div>
    )
} 

export default Signup;