
import { ThreeDots } from  'react-loader-spinner'

function loader() {
  return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
            <ThreeDots
                color="#38B0DE"
                height={80}
                width={80}
            />
      </div>
  );
}

export default loader;