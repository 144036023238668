import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig  = {
    apiKey: "AIzaSyDt53xQiomrYeYAL6r9RyvQeyze82evDNk",
    authDomain: "fetch-d1606.firebaseapp.com",
    projectId: "fetch-d1606",
    storageBucket: "fetch-d1606.appspot.com",
    messagingSenderId: "885091656775",
    appId: "1:885091656775:web:1a856bfcc88a3b750fbd62"
}

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);