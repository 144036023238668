import React, { useState, useEffect } from "react";
import './Cart.css';
import {Container, Row, Col, Button, Form } from "react-bootstrap";
import {useHistory} from 'react-router-dom';
import { connect } from "react-redux";

import { BsPlus } from "react-icons/bs";
import { HiOutlineMinusSm } from "react-icons/hi";
import { RiDeleteBin5Line } from "react-icons/ri";
import {
    adjustItemQty,
    removeFromCart,
  } from "../../redux/Shopping/shopping-actions"
 
import img from '../../Assets/Images/noimages.png';

function CartItem({item, adjustQty, removeFromCart}) {
    let history = useHistory();

    const [input, setInput] = useState(item.qty);
    const [cartCount, setCartCount] = useState(0);
    const [image, setImage] = useState({});
    const [images, setImages] = useState('');

    const increment = () => {
        setInput(prevCount => prevCount + 1)
    }

    const decrement = () => {
        if(input > 1) {
            setInput(prevCount => prevCount - 1)
        }
    }

    useEffect(() => {
        console.log('item cart', item)
    }, [item]);

    useEffect(() => {
        adjustQty(item.id.batchid, input);
    }, [input, item.id.batchid, image]);


    return(
            <div>
                <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                <div style={{padding: 20}}>
                    <Row>
                        <Col xs={12} md={6}>
                            <div>
                                <Row>
                                    <Col xs={12} lg={4}>
                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                            {
                                        item.id.images.length == 0 ?
                                        <img alt="fetch" src={img} style={{width: 120, height: 120}} /> :
                                            <img alt="fetch" src={global.images + "ecommerce_photos/" + item.id.images[0].pei_image} style={{width: 120, height: 120}} />
                                        }
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={8}>
                                        <div className='cart-prodiv'>
                                            <p style={{fontSize: 15, marginBottom: 'unset', marginTop: 15}}>{item.id.name}</p>
                                            <p style={{fontSize: 13, color: '#a9a9a9'}}>{item.id.color_name}, {item.id.size_name}</p>
                                        </div>  
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <div className='cart-qty'>
                                            <div style={{display: 'flex'}}>
                                                <div onClick={decrement} className='cart-decr'>-</div>
                                                <p style={{marginBottom: 0, marginLeft:10, marginRight: 10, fontSize: 14, marginTop: 2}}>{input}</p>
                                                <div onClick={increment} className='cart-decr'>+</div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <p style={{color: '#FF5722', fontSize: 18, fontWeight: 'bold', textAlign: 'center',  marginTop: 20}}>{item.id.currency} {item.id.pb_onlineprice}</p>
                                        <div>
                                            <p onClick={() => removeFromCart(item.id)} style={{fontSize: 14, textAlign: 'center', cursor: 'pointer'}}><RiDeleteBin5Line  color="#000" size={17} style={{marginTop: -4}} /> Remove</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
      adjustQty: (id, value) => dispatch(adjustItemQty(id, value)),
      removeFromCart: (id) => dispatch(removeFromCart(id)),
    };
  };

  export default connect(null, mapDispatchToProps)(CartItem);