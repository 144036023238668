import React from 'react';
import './Slider.css';
import {Container, Row, Col, Button, Carousel } from "react-bootstrap";
import banner1 from '../../Assets/Images/banner1.png';
import {useHistory} from 'react-router-dom';

function Banner (props) {
    let history = useHistory();
    return(
        <div>
            <Carousel>
                    {
                        props.data.map((item) => {
                            return(
                               
                                    <Carousel.Item>
                                         <a onClick={()=> history.push('/product/' + item.link_id + "/category") }>
                                            <img
                                            className="d-block w-100 banner-img"
                                            src={global.images + "ecommerce_photos/" + item.image}
                                            alt="fetch"
                                            style={{width: '100%', height: 320}}
                                            />
                                        </a>
                                    </Carousel.Item>
                                
                            )
                        })
                    }
                </Carousel>
        </div>
    );
}

export default Banner;