import React, {useEffect, useState, useRef} from 'react';
import './Home.css';
import {Container, Row, Col, Button } from "react-bootstrap";
import {useHistory} from 'react-router-dom';
import Slider from "react-slick";
import Sliders from '../../Components/Slider/Slider';
import Deals from '../../Components/Deals/Deals';
import NewArrival from '../../Components/NewArrival/Newarrival';
import TopSelling from '../../Components/TopSelling/TopSelling';
import Skeleton from '@mui/material/Skeleton';
import Category from '../../Components/Category/category';
import Mobilecategory from '../../Components/Category/mobilecategory';
import Gridcategory from '../../Components/Category/gridcategory';
import img from '../../Assets/Images/noimages.png'
import shoes from '../../Assets/Images/shoes.png'
import Products from './cateProducts';
import Loader from '../../Components/Loader/Loader'


function Home (props) {
    // const [timerDay, setTimerDay] = useState('00');
    // const [timerHour, setTimerHour] = useState('00');
    // const [timerMinute, setTimerMinute] = useState('00');
    // const [timerSecond, setTimerSecond] = useState('00');

    const [categorylist, setCategorylist] = useState([]);
    const [sliderlist, setSliderlist] = useState([]);
    const [sliderloading, setSliderloading] = useState(false);
    const [categoryloading, setCategoryloading] = useState(false);
    const [categoryproduct, setCategoryproduct] = useState([]);
    const [banner1, setBanner1] = useState('');
    const [banner2, setBanner2] = useState('');
    const [banner3, setBanner3] = useState('');
    const [banner1id, setBanner1id] = useState('');
    const [banner2id, setBanner2id] = useState('');
    const [banner3id, setBanner3id] = useState('');
    const [posterList, setPosterlist] = useState([]);
    const [arrival, setArrival] = useState([]);
    const [topSelling, setTopSelling] = useState([]);

    const [smallposter, setSmallposter] = useState([]);

    let interval = useRef();
    let history = useHistory();

    // const startTimer = () => {
    //     const countdownDate = new Date("Mar 30, 2022 00:00:00").getTime();
    //     interval = setInterval(() => {
    //         const now = new Date().getTime();
    //         const distance = countdownDate - now;
            
    //         const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    //         const hours = Math.floor((distance % (1000 * 60 * 60 * 24)/(1000 * 60 * 60)));
    //         const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    //         const seconds = Math.floor((distance % (1000 * 60))/ 1000);

    //         if (distance < 0) {
    //             clearInterval(interval.current);
    //         } else {
    //             setTimerDay(days);
    //             setTimerHour(hours);
    //             setTimerMinute(minutes);
    //             setTimerSecond(seconds);
    //         }
    //     }, 1000);
    // }

    // useEffect(() => {
    //     startTimer();
    //     return() => {
    //         clearInterval(interval.current);
    //     }
    // },[])

    useEffect(() => {
        getCategory()
        getSlider()
        getCategoryproducts()
        getBanner()
        getPoster()
        getArrival()
        getTopSelling()
        getSmallposter()
    },[])
    
    const getSlider = () => {
        setSliderloading(true)
       var data = {
           "type": "slider"
       }
        fetch(global.url + "viewBSliderfrontend", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data)
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("Slider response", responseJson)
            setSliderloading(false)
            if(responseJson.error == false){
                setSliderlist(responseJson.data)
            }
            else{
                setSliderlist([])
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getPoster = () => {
        setSliderloading(true)
       var data = {
           "type": "smallbanner"
       }
        fetch(global.url + "viewBSliderfrontend", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data)
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            // console.log("Poster response", responseJson)
            setSliderloading(false)
            if(responseJson.error == false){
                setPosterlist(responseJson.data)
            }
            else{
                setPosterlist([])
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getBanner = () => {
        setSliderloading(true)
       var data = {
           "type": "bigbanner"
       }
        fetch(global.url + "viewBSliderfrontend", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data)
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            // console.log("Banner Respo response", responseJson.data[0])
            setSliderloading(false)
            if(responseJson.error == false){
                setBanner1(responseJson.data[0].image)
                setBanner2(responseJson.data[1].image)
                setBanner3(responseJson.data[2].image)
                setBanner1id(responseJson.data[0].link_id)
                setBanner2id(responseJson.data[1].link_id)
                setBanner3id(responseJson.data[2].link_id)
            }
            else{
                setBanner1('')
                setBanner2('')
                setBanner3('')
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getSmallposter = () => {
     
       var data = {
           "type": "smallposter"
       }
        fetch(global.url + "viewBSliderfrontend", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data)
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("smallposter Respo response", responseJson.data)
            setSmallposter(responseJson.data)
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getCategoryproducts = () => {
        setSliderloading(true)
         fetch(global.url + "displayCategoryProductfrontend", {
             method: "POST",
             headers: {
                 'Content-Type': 'application/json',
                 'APPKEY': 'erp6129'
             },
         })
         .then((response)=> response.json())
         .then((responseJson)=>{
            //  console.log("category product response", responseJson)
             if(responseJson.error == false){
                setSliderloading(false)
                 setCategoryproduct(responseJson.data)
             }
             else{
                setCategoryproduct([])
             }
             
         })
         .catch((error)=>{
             console.log(error)
         })
    }

    const getCategory = () => {
       setCategoryloading(true)
        fetch(global.url + "viewCategoryfrontend", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            // console.log("Category response", responseJson)
            setCategoryloading(false)
            if(responseJson.error == false){
                setCategorylist(responseJson.data)
            }
            else{
                setCategorylist([])
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getArrival = () => {  
        setSliderloading(true)
        var data = {
            "limit": 18
        }
        fetch(global.url + "viewNewArrival", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            //   console.log("Arrival response", responseJson)
              setSliderloading(false)
              if(responseJson.error == false){
                setArrival(responseJson.data)
              }else{
                setArrival([])
              }
              
          })
          .catch((error)=>{
              console.log(error)
          })
      }
      const getTopSelling = () => {  
        setSliderloading(true)
        var data = {
            "limit": 18
        }
        fetch(global.url + "viewTopSalingProducts", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129',
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            //   console.log("Top selling response", responseJson.data)
              setSliderloading(false)
              if(responseJson.error == false){
                setTopSelling(responseJson.data)
              }else{
                setTopSelling([])
              }
              
          })
          .catch((error)=>{
              console.log(error)
          })
      }

    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        autoplay: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1
      };

    return(
        <div>
            {
                    sliderloading ?
                    // <Skeleton sx={{ height: 300 }} animation="wave" variant="rectangular" />
                    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
                        <div style={{marginTop: 200}}>
                            <Loader />
                        </div>
                    </div>
                    :
                <div>
                    <div className='mobilebannerbtop'>
                    {
                        sliderloading ?
                        // <Skeleton sx={{ height: 300 }} animation="wave" variant="rectangular" />
                        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
                            <div style={{marginTop: 50}}>
                                <Loader />
                            </div>
                        </div>
                        :
                        <Sliders  data={sliderlist}/> 
                    }
                </div>
                {
                    categorylist.length == 0 ? null :
                    categorylist[0].cat_grid == 0 ? 
                    <div>
                        <Container className="marginofcontainer desktopcategory">
                            <Category  categorylist={categorylist} categoryloading={categoryloading}/> 
                        </Container>
                        <Container className="marginofcontainer mobilecategory">
                            <Mobilecategory categorylist={categorylist} categoryloading={categoryloading}/>
                        </Container>
                    </div> :

                    <Container>
                        <Gridcategory  categorylist={categorylist} categoryloading={categoryloading}/>
                    </Container> 
                }
                {/* moon */}
                <div className={categorylist.length == 0 ? null :
                    categorylist[0].cat_grid == 0 ? "moonmaindiv": "moonmaindiv gridadjust"}>
                    <div className='laQvkU'>
                        {
                            smallposter.map((item,index) => {
                                return(
                                    <div className="singlediv">
                                        <div className='imagediv'> 
                                            <div className='ipLbIS'>
                                                <a onClick={()=> history.push('/product/' + item.link_id + "/category") }>
                                                    <img alt="fetch" src={global.images + "ecommerce_photos/" + item.image} image className="sc-egiyK cwhUbk"/>
                                                </a>
                                        </div>
                                        </div>
                                    </div>
                                )
                            })
                        }                    
                    </div>
                </div>
                <div style={{padding:10, background: '#fffede', marginTop:10}}>
                    {banner1 == '' ? null :
                            <div>
                                {sliderloading  ? 
                                // <Skeleton sx={{ height: 180 }} animation="wave" variant="rectangular" />
                                null
                                :
                                    <a onClick={()=> history.push('/product/' + banner1id + "/category") }>
                                        <img alt="fetch" src={global.images + "ecommerce_photos/" + banner1}  className="bottom-banner" />
                                    </a>
                                }
                            </div>
                        }
                </div>
                
                <Container>
                    {/* <div style={{marginTop: 30}}>
                        <DayDeal />
                    </div> */}
                    
                    {
                        sliderloading  ?
                            // <Row>
                            //     <Col xs={6} md={3} lg={2}>
                            //         <Skeleton sx={{ height: 140, marginTop: 3 }} animation="wave" variant="rectangular" />
                            //         <Skeleton variant="text" />
                            //         <Skeleton variant="text" />
                            //     </Col>
                            //     <Col xs={6} md={3} lg={2}>
                            //         <Skeleton sx={{ height: 140, marginTop: 3 }} animation="wave" variant="rectangular" />
                            //         <Skeleton variant="text" />
                            //         <Skeleton variant="text" />
                            //     </Col>
                            //     <Col xs={6} md={3} lg={2}>
                            //         <Skeleton sx={{ height: 140, marginTop: 3 }} animation="wave" variant="rectangular" />
                            //         <Skeleton variant="text" />
                            //         <Skeleton variant="text" />
                            //     </Col>
                            //     <Col xs={6} md={3} lg={2}>
                            //         <Skeleton sx={{ height: 140, marginTop: 3 }} animation="wave" variant="rectangular" />
                            //         <Skeleton variant="text" />
                            //         <Skeleton variant="text" />
                            //     </Col>
                            //     <Col xs={6} md={3} lg={2}>
                            //         <Skeleton sx={{ height: 140, marginTop: 3 }} animation="wave" variant="rectangular" />
                            //         <Skeleton variant="text" />
                            //         <Skeleton variant="text" />
                            //     </Col>
                            //     <Col xs={6} md={3} lg={2}>
                            //         <Skeleton sx={{ height: 140, marginTop: 3 }} animation="wave" variant="rectangular" />
                            //         <Skeleton variant="text" />
                            //         <Skeleton variant="text" />
                            //     </Col>
                            // </Row>
                            null
                            :
                
                    categoryproduct.map((item) => {
                        return(
                            <div>
                                {
                            item.products == [] ? 
                            null :
                                <div style={{marginTop: 20}}>
                                    {
                                        item.products.length == 0 ? null :
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div className='catborder'>
                                                <p style={{color: '#286bc7', fontSize: 20, fontWeight: 600 , marginBottom: 'unset'}}>{item.category_name}</p>
                                            </div>
                                            <Button onClick={() => history.push('/product/' + item.category_id + "/category")} variant="light see-all">See All</Button>
                                        </div>
                                    }
                                    
                                    <div>
                                        <Row>
                                            {item.products.map((product,k) => (
                                                
                                                // product.images.length == 0 ? null :
                                                <Col xs={6} md={3} lg={2} style={{marginTop: 10}} className="spacereduce">
                                                    <div>
                                                        <Products key={product.id} product={product} />
                                                    </div>
                                                </Col>
                                                
                                            ))}
                                        </Row>
                                    </div>
                                </div>
                                }
                                </div>

                        )
                    })
                
                }
                </Container>
                <div style={{padding:10, background: '#fffede', marginTop:-200}}>
                        {banner2 == '' ? null :
                        <div >
                                {sliderloading  ? 
                                // <Skeleton sx={{ height: 180 }} animation="wave" variant="rectangular" />
                                null
                                :
                                    <a onClick={()=> history.push('/product/' + banner2id + "/category") }>
                                        <img alt="fetch" src={global.images + "ecommerce_photos/" + banner2}  className="bottom-banner" />
                                    </a>
                                }
                            </div>
                            }
                    </div>
                    <Container>
                    <div style={{marginTop: 10}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        {
                            topSelling.length == 0 ? null :
                            <div className='catborder'>
                                <p style={{color: '#286bc7', fontSize: 20, fontWeight: 600 , marginBottom: 'unset'}}>Top Selling Products</p>
                            </div>}
                            {/* <h2 style={{color: '#000', fontSize: 28, fontWeight: 'bold'}}>Top Selling Products</h2> */}
                            {/* <Button onClick={() => history.push('/product')} variant="light see-all">See All</Button> */}
                        </div>
                        <div>
                            {
                                sliderloading ?
                                // <Row>
                                //     <Col xs={6} md={3} lg={2}>
                                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                                //         <Skeleton variant="text" />
                                //         <Skeleton variant="text" />
                                //     </Col>
                                //     <Col xs={6} md={3} lg={2}>
                                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                                //         <Skeleton variant="text" />
                                //         <Skeleton variant="text" />
                                //     </Col>
                                //     <Col xs={6} md={3} lg={2}>
                                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                                //         <Skeleton variant="text" />
                                //         <Skeleton variant="text" />
                                //     </Col>
                                //     <Col xs={6} md={3} lg={2}>
                                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                                //         <Skeleton variant="text" />
                                //         <Skeleton variant="text" />
                                //     </Col>
                                //     <Col xs={6} md={3} lg={2}>
                                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                                //         <Skeleton variant="text" />
                                //         <Skeleton variant="text" />
                                //     </Col>
                                //     <Col xs={6} md={3} lg={2}>
                                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                                //         <Skeleton variant="text" />
                                //         <Skeleton variant="text" />
                                //     </Col>
                                // </Row>
                                null
                                :
                                <TopSelling data={topSelling} />
                            }
                        </div>
                    </div>
                    <div style={{marginTop: 30}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        {
                            posterList.length == 0 ? null :
                            <div className='catborder'>
                                <p style={{color: '#286bc7', fontSize: 20, fontWeight: 600 , marginBottom: 'unset'}}>Special Deals</p>
                            </div>}
                            {/* <h2 className='font' style={{color: '#000', fontSize: 28, fontWeight: 'bold'}}>Special Deals</h2> */}
                        </div>
                        <div>
                            {sliderloading ?
                                // <Row>
                                //     <Col xs={12} md={6}>
                                //         <Skeleton sx={{ height: 220 }} animation="wave" variant="rectangular" />
                                //     </Col>
                                //     <Col xs={12} md={6}>
                                //         <Skeleton sx={{ height: 220 }} animation="wave" variant="rectangular" />
                                //     </Col>
                                // </Row> 
                                null
                                :
                                <Deals data={posterList} />
                        }   
                        </div>
                    </div>
                    <div style={{marginTop: 20}}>
                        {
                            arrival.length == 0 ? null :
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div className='catborder'>
                                    <p style={{color: '#286bc7', fontSize: 20, fontWeight: 600 , marginBottom: 'unset'}}>New Arrival</p>
                                </div>
                                {/* <h2 style={{color: '#000', fontSize: 28, fontWeight: 'bold'}}>New Arrival</h2> */}
                            </div>
                        }
                        
                        <div>
                            {
                                sliderloading ?
                                // <Row>
                                //     <Col xs={6} md={3} lg={2}>
                                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                                //         <Skeleton variant="text" />
                                //         <Skeleton variant="text" />
                                //     </Col>
                                //     <Col xs={6} md={3} lg={2}>
                                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                                //         <Skeleton variant="text" />
                                //         <Skeleton variant="text" />
                                //     </Col>
                                //     <Col xs={6} md={3} lg={2}>
                                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                                //         <Skeleton variant="text" />
                                //         <Skeleton variant="text" />
                                //     </Col>
                                //     <Col xs={6} md={3} lg={2}>
                                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                                //         <Skeleton variant="text" />
                                //         <Skeleton variant="text" />
                                //     </Col>
                                //     <Col xs={6} md={3} lg={2}>
                                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                                //         <Skeleton variant="text" />
                                //         <Skeleton variant="text" />
                                //     </Col>
                                //     <Col xs={6} md={3} lg={2}>
                                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                                //         <Skeleton variant="text" />
                                //         <Skeleton variant="text" />
                                //     </Col>
                                // </Row>
                                null
                                :
                                <NewArrival data={arrival} /> 
                            }
                        </div>
                    </div>
                </Container>
                <div style={{padding:10, background: '#fffede', marginTop:10}}>
                    {banner3 == '' ? null :
                        <div >
                            {sliderloading  ? 
                            // <Skeleton sx={{ height: 180 }} animation="wave" variant="rectangular" />
                            null
                            :
                                <a onClick={()=> history.push('/product/' + banner3id + "/category") }>
                                    <img alt="fetch" src={global.images + "ecommerce_photos/" + banner3}  className="bottom-banner" />
                                </a>
                            }
                        </div>
                    }
                </div>
            </div> }
        </div>
    );
}
export default Home;