import React from 'react';
import Slider from "react-slick";
import './Deals.css'
import { FiCheck } from "react-icons/fi";
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import {useHistory} from 'react-router-dom';

const source = [
    {
        'image' : 'deal2'
    },
    {
        'image' : 'deals1'
    },
    {
        'image' : 'deal2'
    },
  ];

function Deals(props){
  let history = useHistory();
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        loop: true,
        speed: 800,
        slidesToShow: 2,
        nextArrow: <div style={{backgroundColor: 'gray', padding: 10}}><BsArrowRightShort color={"#7c003e"}/></div>,
        prevArrow: <div style={{backgroundColor: 'gray', padding: 10}}><BsArrowLeftShort color={"#7c003e"}/></div>,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
    return(
        <div>
            <Slider {...settings}>
                { props.data.map((item, index) => (
                  <a onClick={()=> history.push('/product/' + item.link_id + "/category") }>
                      <img alt="fetch" className="slick-image imagedeals"  src={global.images + "ecommerce_photos/" + item.image} />
                  </a>
                ))}
                
            </Slider>
        </div>
    );
}
export default Deals;