import React from 'react';
import Slider from "react-slick";
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import {Container, Row, Col, Button } from "react-bootstrap";
import {useHistory} from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';


function Category(props){
    let history = useHistory();
  
    return(
        <Row>
            {
                props.categoryloading ? 
                    <Row>
                        <Col xs={6} md={3} lg={2} style={{marginTop: 50}}>
                            <div>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <Skeleton sx={{ width: 130, height: 120 }} animation="wave" variant="circular" />
                                </div>
                                <Skeleton variant="text" />
                            </div>
                        </Col>
                        <Col xs={6} md={3} lg={2} style={{marginTop: 50}}>
                            <div>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <Skeleton sx={{ width: 130, height: 120 }} animation="wave" variant="circular" /> 
                                </div>
                                <Skeleton variant="text" />
                            </div>
                        </Col>
                    </Row>
                :
                <div className="mob-category">
                    <div className="mob-category-menu">
                        {
                            props.categorylist.map((item) => {
                                return(
                                    <div className="cate-list">
                                        <a onClick={() => history.push('/product/' + item.cat_id + "/category")} style={{cursor: "pointer"}}>
                                            <div className="categ-img">
                                                <img alt="fetch" src={global.images + 'ecommerce_photos/' + item.cat_image} /> 

                                            </div>
                                            <p className='categorytxt'>{item.cat_name}</p>
                                        </a>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
        </Row>
    );
}
export default Category;